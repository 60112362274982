import React, { useEffect, useState } from "react";
import "./RemarkHistory.scss";
import { Button, Result } from "antd";
import Avatar from "@mui/material/Avatar";
import "../../Reports/BRSR/Brsr.scss";
import {
  useLazyGetRemarkHistoryQuery,
  usePostAddRemarkMutation,
  useLazyGetUserDetailByIdQuery
} from "../../../state/api";
import { Drawer } from "@mui/material";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";
import TextAreaWithWordCount from "../../../components/BRSRComponets/TextAreaInputWithCount/TextAreaWithCount";
import WidgetLoader from "../../../components/Loaders/WidgetLoader";
import { useSelector } from "react-redux";

const RemarkHistory = ({ data, questionId, whiteButton }) => {
  const openSnackbar = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [getRemarkHistory, reusltRemark] = useLazyGetRemarkHistoryQuery();
  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [postAddReark] = usePostAddRemarkMutation();
  const [remarkValue, setRemarkValue] = useState();
  const [remarkData, setRemarkData] = useState([]);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);



  useEffect(() => {
    if (drawerOpen && data) {
      getRemarkHistory({
        facilityId: data.facId,
        reportId: data.reportId,
        questionId: questionId
      });
    }
  }, [drawerOpen, data]);

 

  useEffect(() => {
    if (reusltRemark?.data?.data) {
      setRemarkData(reusltRemark?.data?.data);
    }
  }, [reusltRemark?.data?.data]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRemarks = (e) => {
    setRemarkValue(e.target.value);
  };

  const handleSubmit = async () => {
    const body = {
      questionId: questionId,
      facilityId: data.facId,
      reportId: data.reportId,
      comment: remarkValue
    };
    try {
      const res = await postAddReark(body);
      if (res?.data?.success) {
        setRemarkValue();
        getRemarkHistory({
          facilityId: data.facId,
          reportId: data.reportId,
          questionId: questionId,
          acceptType: "multi"
        });
        openSnackbar(res?.data?.message, "success");
      } else {
        openSnackbar(res?.error?.data?.message, "warning", 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const timeAgo = (date) => {
    const now = new Date();
    const commentDate = new Date(date);
    const seconds = Math.floor((now - commentDate) / 1000);
    
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) return `${interval} year${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) return `${interval} month${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return `${interval} day${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return `${interval} hour${interval > 1 ? 's' : ''} ago`;
    interval = Math.floor(seconds / 60);
    if (interval >= 1) return `${interval} minute${interval > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };

  return (
    <>
      <div style={{ position: "relative" }} className="remark-history-drawer">
        <Drawer open={drawerOpen} onClose={toggleDrawer} anchor="right">
          <div className="p-4 remark-history-drawer">
            <div onClick={toggleDrawer}>
              <Button size="meduim" style={{ backgroundColor: "#F9F9F9" }}>
                <img src="/images/icons/buttonCross-icon.svg" alt="icon" /> Close
              </Button>
            </div>
            <p className="heading-h6 mt-4 ">Remarks history</p>

            <div className="remark-msg-container">
              {!reusltRemark?.isLoading ? (
                remarkData?.length === 0 ? (
                  <div
                    className="empty-wrapper d-flex flex-column px-4 py-3 mt-3"
                    style={{ height: "224px", background: "#F2F3F7" }}
                  >
                    <img src="/images/BRSR/maker-icon.svg" alt="info-icon" width={48} className="mb-2" />
                    <p className="subtitle-1">No remarks found!</p>
                    <p className="caption-1-Regular">
                      For better communication, add remarks using the below input field.
                    </p>
                  </div>
                ) : (
                  remarkData?.length > 0 && (
                    <div>
                      {remarkData.map((item, index) => (
                        <div key={index + item} className="mt-3">
                          <div className="profile-section d-flex gap-2">
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            <div>
                              <p className="subtitle-1 mb-0">{item.name}</p>
                              <p className="caption-1-Regular  mb-0" style={{ color: "#16161EB2" }}>
                                {item.role} <span className="caption-1-Regular">{"| " + timeAgo(item.createdAt)}</span>
                              </p>
                            </div>
                          </div>

                          <div className="msg-container">
                            <p className="body-2-Regular mt-3" style={{ minWidth: "346px" }}>
                              {item.commentText}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                )
              ) : (
                <div className="empty-wrapper">
                  <WidgetLoader />
                </div>
              )}
            </div>

            {/* <p className="reply-text-button">REPLY</p> */}

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn btn-outline-success"
                style={{ width: "100%", color: "#16161E" }}
                onClick={handleSubmit}
              >
                Load more
              </button>
            </div> */}

            <div className="new-remark">
              <div style={{ marginBottom: "1rem" }} className="mt-3">
                <p className="subtitle-1">New remark</p>
                <div className="form-textfield-container">
                  <div className="brsr-input mt-2">
                    <TextAreaWithWordCount
                      row={8}
                      placeholder="Write here"
                      maxCharacters={500}
                      name="newremark"
                      value={remarkValue}
                      onChange={handleRemarks}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="btn btn-success" style={{ width: "100%" }} onClick={handleSubmit}>
                  Done
                </button>
              </div>
            </div>
          </div>
        </Drawer>
      </div>

      {whiteButton ? (
        <Button size="meduim" style={{ backgroundColor: "" }} onClick={toggleDrawer} disabled={ data.facId ? false :true}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            style={{ marginRight: "8px" }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 5.1998H3V3.7998L0 3.7998V5.1998ZM0 8.1998H4.5V6.7998L0 6.7998V8.1998ZM0 11.1998V9.7998H6V11.1998H0ZM0 14.1998H7.5V12.7998H0V14.1998Z"
              fill="#16161E"
              fill-opacity="0.7"
            />
            <path
              d="M9.36 10.98L9 13.5L11.52 13.14L18 6.66L15.84 4.5L9.36 10.98Z"
              stroke="#16161E"
              stroke-opacity="0.7"
              stroke-width="1.4"
              stroke-linejoin="round"
            />
          </svg>
          Remarks History
        </Button>
      ) : (
        <div onClick={toggleDrawer} className="cursor-pointer">
          <div class="remark-history-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 5.20005H3V3.80005L0 3.80005V5.20005ZM0 8.20005H4.5V6.80005L0 6.80005V8.20005ZM0 11.2V9.80005H6V11.2H0ZM0 14.2H7.5V12.8H0V14.2Z"
                fill="#1F93EF"
              />
              <path
                d="M9.36 10.98L9 13.5L11.52 13.14L18 6.66L15.84 4.5L9.36 10.98Z"
                stroke="#1F93EF"
                stroke-width="1.4"
                stroke-linejoin="round"
              />
            </svg>
            <div class="content">
              <p className="m-0">Remarks</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RemarkHistory;
