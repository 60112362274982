import React, { useState, useEffect, useRef } from "react";

const EditableCell = ({ readOnly, value, rowIndex, field, onValueChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [cellValue, setCellValue] = useState(value); // Initial value from props
  const textareaRef = useRef(null);

  const handleCellClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    // onValueChange(rowIndex, field, cellValue);
  };

  const handleChange = (e) => {
    setCellValue(e.target.value);
    onValueChange(rowIndex, field, e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (textareaRef.current && !textareaRef.current.contains(event.target)) {
        handleBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCellValue(value);
  }, [value, isEditing]);

  return (
    <td onClick={handleCellClick} style={{ position: "relative" }}>
      {isEditing ? (
        <textarea
          readOnly={readOnly}
          ref={textareaRef}
          value={cellValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          rows={3}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "636px",
            height: "162px",
            zIndex: 1000,
            border: "1px solid #1F93EF",
            padding: "4px",
            resize: "both",
            background: "white"
          }}
          autoFocus
        />
      ) : (
        <input
          type="text"
          value={cellValue}
          readOnly
          placeholder={readOnly ? "" : "Click to write"}
          style={{
            width: "100%",
            border: "none",
            background: "none",
            outline: "none",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        />
      )}
    </td>
  );
};

export default EditableCell;
