import React from 'react'
import PropTypes from "prop-types";
import { Button } from "antd";
const AddRowButton = ({addRow ,buttonPosition,buttonText ,size ,customeClass ,color}) => {
  return (
    <div className={`${customeClass} d-flex justify-content-${buttonPosition} text-${color} w-100`}>
    <Button size={size} className="borderlLine-btn" onClick={addRow}>
      {/* + Add a new row */}
      {buttonText}
    </Button>
    </div>
  )
}

AddRowButton.prototype ={
    addRow: PropTypes.func.isRequired,
    buttonPosition : PropTypes.string,
    size: PropTypes.string

}
AddRowButton.defaultProps = {
    buttonPosition: 'end',
    size:"large" 
  };

export default AddRowButton