export const brsrReportEndPoint = (build) => ({
  getAllFacility: build.query({
    query: () => ({
      url: `reports/getAllFacilities`
    }),
    providesTags: ["facility"]
  }),

  getAllMember: build.query({
    query: (arg) => ({
      url: `reports/getUsers`,
      params: { ...arg }
    }),

    providesTags: ["user"]
  }),

  postAssignContributor: build.mutation({
    query: (postBody) => ({
      url: `reports/assignContributor`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  getUserCountStatus: build.query({
    query: (arg) => ({
      url: `reports/usersCountStatusWise`,
      params: { ...arg }
    }),

    providesTags: ["userCountStatus"]
  }),

  postCreateBrsrReport: build.mutation({
    query: (postBody) => ({
      url: `reports`,
      method: "POST",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  postCreateGhgReport: build.mutation({
    query: (postBody) => ({
      url: `report/generate/ghg`,
      method: "POST",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  getPreviousYearReport: build.query({
    query: (arg) => ({
      url: `reports/getPreviousBRSRReports`,
      params: { ...arg }
    }),

    providesTags: ["previousreport"]
  }),

  getAllReports: build.query({
    query: (arg) => ({
      url: `reports/getreports`,
      params: { ...arg }
    }),

    providesTags: ["Reports"]
  }),

  deleteReport: build.mutation({
    query: (id) => ({
      url: `/reports/${id}`,
      method: "DELETE"
    }),
    invalidatesTags: ["Reports"]
  }),

  putloackUnlockReport: build.mutation({
    query: (postBody) => ({
      url: `reports/lock-unlock-report`,
      method: "PUT",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["Reports"]
  }),

  getContributors: build.query({
    query: (arg) => ({
      url: `reports/getContributors`,
      params: { ...arg }
    }),

    providesTags: ["Contributors"]
  }),

  getContributorsCountRoleWise: build.query({
    query: (arg) => ({
      url: `reports/contributorsCountRoleWise`,
      params: { ...arg }
    }),

    providesTags: ["Contributorscount"]
  }),

  getExitingReports: build.query({
    query: (arg) => ({
      url: `reports/existing-reports`,
      params: { ...arg }
    }),

    providesTags: ["Exitingreports"]
  }),

  putConfirmTeamRole: build.mutation({
    query: (postBody) => ({
      url: `reports/confirmTeam`,
      method: "PUT",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["Role"]
  }),
  putUpdateRole: build.mutation({
    query: (postBody) => ({
      url: `reports/changeRole`,
      method: "PUT",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["ChnageRole"]
  }),

  getReportOverView: build.query({
    query: (arg) => ({
      url: `reports/dashboard/getReportOverview`,
      params: { ...arg }
    }),
    providesTags: ["ReportOverview"]
  }),

  getAssignQuestionStatus: build.query({
    query: (arg) => ({
      url: `reports/dashboard/assigned-questions`,
      params: { ...arg }
    }),
    providesTags: ["Assignquestion"]
  }),

  getOverAllQuestionStatus: build.query({
    query: (arg) => ({
      url: `reports/dashboard/question-status`,
      params: { ...arg }
    }),
    providesTags: ["Overallstatus"]
  }),

  getSectionWsieStatus: build.query({
    query: (arg) => ({
      url: `reports/dashboard/question-status/section`,
      params: { ...arg }
    }),
    providesTags: ["Facilitywise"]
  }),

  getAssignmentStatus: build.query({
    query: (arg) => ({
      url: `reports/questions/questionsStatusSectionWise`,
      params: { ...arg }
    }),
    providesTags: ["Assignmentstatus"]
  }),

  getPrincipalWsieStatus: build.query({
    query: (arg) => ({
      url: `reports/dashboard/question-status/principle`,
      params: { ...arg }
    }),
    providesTags: ["Principalwise"]
  }),

  getLeadershipStatus: build.query({
    query: (arg) => ({
      url: `/reports/dashboard/question-status/leadership`,
      params: { ...arg }
    }),
    providesTags: ["Leadership"]
  }),

  getFacilityWsieStatus: build.query({
    query: (arg) => ({
      url: `reports/dashboard/question-status/facility`,
      params: { ...arg }
    }),
    providesTags: ["Facility"]
  }),

  getSections: build.query({
    query: (arg) => ({
      url: `reports/questions/questionsStatusSectionWise`,
      params: { ...arg }
    }),
    providesTags: ["Sections"]
  }),

  getSubSections: build.query({
    query: (arg) => ({
      url: `reports/questions/getSubSections`,
      params: { ...arg }
    }),
    providesTags: ["Sections"]
  }),

  getQuestionSubSectionWise: build.query({
    query: (arg) => ({
      url: `reports/questions/getQuestionsSubSectionsWise`,
      params: { ...arg }
    }),
    providesTags: ["Sections"]
  }),

  postSaveAnswer: build.mutation({
    query: (postBody) => ({
      url: `reports/questions/submitAnswer`,
      method: "POST",
      body: postBody
    }),
    providesTags: ["Facilitywise", "Answers"]
  }),
  getBrsrFacility: build.query({
    query: (arg) => ({
      url: `reports/dashboard/getAllFacilities`,
      params: { ...arg }
    }),
    providesTags: ["Facility"]
  }),
  getAssignmentQuestions: build.query({
    query: (arg) => ({
      url: `reports/questions/getQuestions`,
      params: { ...arg }
    }),
    providesTags: ["Questions"]
  }),
  postAssignQuestion: build.mutation({
    query: (postBody) => ({
      url: `reports/questions/assign-questions`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Assignmentstatus"]
  }),
  postBulkAssignQuestion: build.mutation({
    query: (postBody) => ({
      url: `reports/questions/assign-bulk-questions`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Assignmentstatus"]
  }),
  postChangeDepartment: build.mutation({
    query: (postBody) => ({
      url: `reports/questions/changeDepartment`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions"]
  }),
  postSubmitForApproval: build.mutation({
    query: (postBody) => ({
      url: `reports/questions/finalSubmit`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Sections", "Answers"]
  }),

  getRemarkHistory: build.query({
    query: (arg) => ({
      url: `/reports/questions/viewRemarks`,
      params: { ...arg }
    }),
    providesTags: ["Remarkhistory"]
  }),

  postAddRemark: build.mutation({
    query: (postBody) => ({
      url: `/reports/questions/addRemark`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: []
  }),

  putRejectAnswer: build.mutation({
    query: (postBody) => ({
      url: `/reports/questions/rejectAnswer`,
      method: "PUT",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Sections"]
  }),

  putRejectAllAnswer: build.mutation({
    query: (postBody) => ({
      url: `/reports/questions/rejectAllAnswer`,
      method: "PUT",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Sections"]
  }),

  putApproveAnswer: build.mutation({
    query: (postBody) => ({
      url: `/reports/questions/approveAnswer`,
      method: "PUT",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Sections"]
  }),
  putAcceptAnswer: build.mutation({
    query: (postBody) => ({
      url: `/reports/questions/acceptAnswer`,
      method: "PUT",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Sections"]
  }),

  postMergeAnswer: build.mutation({
    query: (postBody) => ({
      url: `/reports/questions/mergeAnswer`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Sections"]
  }),

  postUnmergeAnswer: build.mutation({
    query: (postBody) => ({
      url: `/reports/questions/undoMerge`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Questions", "Sections"]
  }),

  postUploadCover: build.mutation({
    query: (postBody) => ({
      url: `/reports/preview/upload-cover`,
      method: "POST",
      formData: true,
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Coverpage", "Logo"]
  }),

  deleteCustomUpload: build.mutation({
    query: (id) => ({
      url: `/reports/preview/removeCustomUpload/${id}`,
      method: "DELETE"
    }),
    invalidatesTags: ["Coverpage", "Logo"]
  }),

  postUploadImage: build.mutation({
    query: (postBody) => ({
      url: `/reports/preview/upload-image`,
      method: "POST",
      formData: true,
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    }
  }),

  getCover: build.query({
    query: (arg) => ({
      url: `/reports/preview/getAllCoverPage`,
      params: { ...arg }
    }),
    providesTags: ["Coverpage"]
  }),

  getAllContentPage: build.query({
    query: (arg) => ({
      url: `/reports/preview/getAllContentPage`,
      params: { ...arg }
    }),
    providesTags: ["Contentpage"]
  }),

  getReportInfo: build.query({
    query: (arg) => ({
      url: `/reports/preview/getReportInfo`,
      params: { ...arg }
    }),
    providesTags: ["Report"]
  }),

  getNotifications: build.query({
    query: (arg) => ({
      url: `/reports/notifications/view`,
      params: { ...arg }
    }),
    providesTags: ["Notif"]
  }),

  getAllLogo: build.query({
    query: (arg) => ({
      url: `/reports/preview/getAllLogo`,
      params: { ...arg }
    }),
    providesTags: ["Logo"]
  }),

  getAllAnswers: build.query({
    query: (arg) => ({
      url: `/reports/questions/getAnswers`,
      params: { ...arg }
    }),
    providesTags: ["Answers"]
  }),

  postAllCoverData: build.mutation({
    query: (postBody) => ({
      url: `/reports/preview/saveReportCover`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  postAllIntroData: build.mutation({
    query: (postBody) => ({
      url: `/reports/preview/saveReportIntro`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  postEvidenceData: build.mutation({
    query: (postBody) => ({
      url: `evidence`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  postEditAfterMerge: build.mutation({
    query: (postBody) => ({
      url: `reports/questions/editAfterMerge`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  getEvidenceData: build.query({
    query: (arg) => ({
      url: `/evidence/question-evidence`,
      params: { ...arg }
    }),
    providesTags: ["Evidence"]
  }),

  getDownLoadReport: build.query({
    query: (arg) => ({
      url: `/reports/preview/downloadWord`,
      params: { ...arg }
    }),
    providesTags: ["Report"]
  }),

  getDownLoad: build.query({
    query: (arg) => ({
      url: `/report/download`,
      params: { ...arg }
    }),
    providesTags: ["Report"]
  })
});
