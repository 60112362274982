import React, { useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const ReportPdfPreview = () => {
  const reportRef = useRef();
  const [showModal, setShowModal] = useState(false);

  // Function to generate the PDF
  const generatePDF = async () => {
    const input = reportRef.current;
    const canvas = await html2canvas(input, {
      scale: 2,
      useCORS: true
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let heightLeft = imgHeight;
    let position = 0;

    while (heightLeft >= 0) {
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
      if (heightLeft >= 0) {
        pdf.addPage();
      }
      position = heightLeft < 0 ? heightLeft : position;
    }

    pdf.save("report.pdf");
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <button onClick={toggleModal} style={buttonStyle}>
        Preview Report
      </button>

      {showModal && (
        <div style={modalOverlayStyle}>
          <div style={modalHeaderStyle}>
            <div className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M3 6H21M3 12H21M3 18H21"
                  stroke="white"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
            </div>

            <div className="d-flex gap-3">
              <div onClick={generatePDF} className="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19 20L19 18L5 18L5 20L19 20ZM19 10L15 10L15 4L9 4L9 10L5 10L12 17L19 10Z" fill="white" />
                </svg>
              </div>
              <div className="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_252_12503)">
                    <path
                      d="M19 8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM16 19H8V14H16V19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM18 3H6V7H18V3Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_252_12503">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_252_12506)">
                    <path
                      d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_252_12506">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div onClick={toggleModal} className="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6 18L18 6M18 18L6 6"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div style={modalContentStyle}>
            {/* Report Content */}
            <div ref={reportRef} style={reportStyle} dangerouslySetInnerHTML={{ __html: rawHtml }} />
          </div>
        </div>
      )}
    </div>
  );
};

const buttonStyle = {
  padding: "10px 15px",
  backgroundColor: "#007BFF",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer"
};

const modalOverlayStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  width: "100%",
  height: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
};

const modalHeaderStyle = {
  width: "100%",
  backgroundColor: "#2E2E3A",
  padding: "16px 24px",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000, // Ensure header is above other content
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
};

const headerButtonStyle = {
  padding: "10px 15px",
  backgroundColor: "#007BFF",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer"
};

const modalContentStyle = {
  width: "100%",
  maxWidth: "800px",
  backgroundColor: "#fff",
  overflow: "hidden",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  padding: "10px", // Add some padding
  maxHeight: "100vh", // Limit the height of the modal content
  overflowY: "auto", // Allow scrolling
  marginTop: "60px" // Adjust for the fixed header height
};

const reportStyle = {
  width: "100%",
  minHeight: "400px",
  padding: "5mm",
  boxSizing: "border-box",
  // overflow: " hidden"
};

const rawHtml = `<!DOCTYPE html>
<html lang="en">

<head>
  <!-- Required meta tags -->
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    crossorigin="anonymous">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Inter">
  <style>
    body {
      margin: 0;
      padding: 0;
      text-align: justify;
    }

    .page {
      page-break-after: always;
      page-break-inside: avoid;
      display: flex;
      flex-direction: column;
      font-family: "Inter";

    }

    .page .page-header {
      color: #16161E;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;

    }

    .page .dotted-line {
      flex-grow: 1;
      border-bottom: 1px dashed #C6CBD9;
      /* margin: 1% 2% 0; */

    }

    .page .normal-input {
      padding: 0.8rem;
      border-bottom: 1px solid var(--Neutral-50, #C6CBD9);
      border-top: 1px solid var(--Neutral-50, #50CFAB);
      color: #16161E;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .page .normal-input-sectionC {
      padding: 0.8rem;
      border-bottom: 1px solid var(--Neutral-50, #C6CBD9);
      border-top: 1px solid var(--Neutral-50, #61B7CD);
      color: #16161E;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .page .gray-border-input {
      border-bottom: 1px solid var(--Neutral-50, #C6CBD9);
      padding: 0.8rem;
    }

    .page .rowspan-border {
      border-left: 1px solid var(--Neutral-50, #50CFAB) !important;
    }

    .page .rowspan-td-border {
      border-left: 1px solid var(--Neutral-50, #C6CBD9) !important;
    }

    .page .content-wrapper {
      height: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      color: #16161E;
    }

    .page .main-title {
      color: #16161E;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: 2rem;
      /* color: #16161E;
      font-size: 34px;
      font-weight: normal;
      margin-bottom: 2rem; */
    }

    .page .content-title {
      font-size: 1rem;
      font-weight: 700;
    }

    .page .content-subtitle {
      font-size: .7rem;
      color: #535362;
    }

    .page .table-title {
      color: #16161E;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin-top: 1rem;
    }

    .page .table-sub-title {
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

    }

    .page .content {
      margin-top: 5%;
    }

    .page .section-title {
      color: #16161E;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;

    }

    @media print {
      .page .principle-button-type {
        background: #61B7CD;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    }

    .page .principle-button-type {
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: #61B7CD;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      width: fit-content;
    }

    @media print {
      .page .principle-wrapper {
        background: rgba(97, 183, 205, 0.02);
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    }

    .page .principle-wrapper {
      border-radius: 6px;
      border: 1px solid var(--Cards-PWPD-Stroke, #CAEFF9);
      background: rgba(97, 183, 205, 0.02);
      display: flex;
      padding-right: 12px;
      align-items: center;
      gap: 12px;
    }

    .page .principle-title {
      border-radius: 6px 0 0 6px;
      background: var(--Progress-PWPD, #61B7CD);
      display: flex;
      padding: 8px 14px;
      color: #FFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
      text-wrap: nowrap;
    }

    .page .principle-description {
      color: rgba(22, 22, 30, 0.70);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

    }


    .page hr {
      opacity: 1;
      margin: .2rem 0;
    }

    table {
      font-size: .7rem;
      margin-bottom: 0;
      width: 100%;
      word-wrap: break-word;
      table-layout: fixed;
      overflow: hidden;
      /* border-collapse: collapse; */
    }

    th {
      background: rgba(80, 207, 171, 0.02);
      border-top: 1px solid var(--Progress-GD, #50cfab);
      border-bottom: 1px solid var(--Progress-GD, #50cfab);
      border-left: 1px solid var(--Progress-GD, #50cfab);
      padding: 0.8rem !important;
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

    }

    td {
      padding: 0.8rem !important;
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      border-right: 1px solid var(--Neutral-50, #C6CBD9);

    }

    td:first-child,
    th:first-child {
      border-left: unset;
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }

    .table-sectionB .table {
      /* font-size: .7rem; */
      margin-bottom: 0;
      width: 100%;
      word-wrap: break-word;
      table-layout: fixed;
      overflow: hidden;
    }

    .table-sectionB th {
      background: rgba(80, 207, 171, 0.02);
      border-top: 1px solid var(--Progress-GD, #dc96c2);
      border-bottom: 1px solid var(--Progress-GD, #dc96c2);
      border-left: 1px solid var(--Progress-GD, #dc96c2);
      padding: 12px;
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    .table-sectionB td {
      padding: 0.8rem;
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      border: 1px solid var(--Neutral-50, #c6cbd9);

    }

    .table-sectionB td:first-child,
    .table-sectionB th:first-child {
      border-left: unset;
      border-top: unset;
    }

    .table-sectionB td:last-child,
    .table-sectionB th:last-child {
      border-right: none;
    }

    .table-sectionC .table {
      font-size: .7rem;
      margin-bottom: 0;
      width: 100%;
      word-wrap: break-word;
      table-layout: fixed;
      overflow: hidden;
    }

    .table-sectionC th {
      background: rgba(80, 207, 171, 0.02);
      border-top: 1px solid #61B7CD;
      border-bottom: 1px solid #61B7CD;
      border-left: 1px solid #61B7CD;
      padding: 12px;
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

    }

    .table-sectionC td {
      padding: 0.8rem;
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      border: 1px solid var(--Neutral-50, #c6cbd9);
    }

    .table-sectionC td:first-child,
    .table-sectionC th:first-child {
      border-left: unset;

    }

    .table-sectionC td:last-child,
    .table-sectionC th:last-child {
      border-right: none;
    }



    @media print {
      table {
        zoom: 90%;
      }
    }

    .index-page .section {
      display: flex;
      align-items: center;
    }

    .index-page .section:not(:last-child) {
      /* margin-bottom: 3rem; */
    }

    .index-page .sub-section {
      margin-bottom: 0 !important;
    }

    .index-page .sub-section .page-number {
      font-weight: 400;
    }

    .index-page .sub-section .section-title {
      color: #16161E;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

    }



    .index-page li {
      margin-bottom: 0.8rem;
    }

    .index-page li .sub-text {
      width: 75%;
      color: #5B5B5B;
      font-size: .8rem;
    }

    .index-page .dotted-line {
      flex-grow: 1;
      border-bottom: 1px dashed #C6CBD9;
      margin: 1% 2% 0;

    }

    .index-page .page-number {
      margin-left: auto;
      font-weight: 600;
      letter-spacing: .07px;
    }

    /* .general-disclosure-page .header-column {
      font-weight: bold;
      width: 50%;
    }

    .general-disclosure-page .value-column {
      opacity: .8;
    } */

    .header .section-wrapper {
      opacity: .5;
      font-size: .5rem;
    }

    .header .color-blacky {
      color: #16161E;
    }

    @media print {
      .page .section-b-about {
        background: var(--Cards-MPD-Fill-Gradiant, linear-gradient(246deg, #fceefa -0.13%, #F9E1EF 100%)) !important;
      }
    }

    @media print {
      .page .section-c-about {
        background: var(--Cards-PWPD-Fill-Gradiant, linear-gradient(246deg, #e6f4f8 -0.13%, #CAEFF9 100%)) !important;
      }
    }

    @media print {
      .page .about-page {
        background: linear-gradient(246deg, #e5eee8 -0.13%, #C1F4E6 100%);
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    }

    .page .about-page .section-name {
      color: rgba(22, 22, 30, 0.70);
      font-size: 2.1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 42px;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }


    .page .about-page .section-title {
      color: #16161E;
      font-size: 3rem;
      font-style: normal;
      font-weight: 500;
      line-height: 56px;
      text-transform: uppercase;
      margin-bottom: 3rem;
    }

    .page .about-page .section-info {
      color: rgba(22, 22, 30, 0.70);
      font-size: 2.1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 42px;
      /* width: 62.3rem; */
    }

    .page .intro-page .first-para-intro {
      color: rgba(22, 22, 30, 0.70);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .page .intro-page .second-para-intro {
      color: #16161E;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px
    }

    .page .intro-page .message-text {
      color: #00984D;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 42px;
    }

    .page .key-highlight-page .title {
      color: #16161E;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 42px;
    }

    .page .key-highlight-page .year {
      color: rgba(22, 22, 30, 0.70);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    .page .key-highlight-page .headline {
      color: 16161E;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    .page .key-highlight-page .details {
      color: rgba(22, 22, 30, 0.70);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .page .key-highlight-page .image-container {
      display: flex;
      width: 196px;
      height: 120px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 24px 0px;
      border: 4px solid var(--Neutral-5, #F6F9FA);
    }

    .page .table-not-fixed .table {
      font-size: .7rem;
      margin-bottom: 0;
      width: 100%;
      word-wrap: break-word;
      table-layout: auto !important
        /* overflow: hidden; */
    }

    .page .intro-page .ceo-profile {
      display: flex;
      align-items: center;
      border: 2px solid var(--Neutral-5, #F6F9FA);
      border-radius: 50%;
      min-width: 230px;
      height: 230px;
      background: lightgray;
    }

    .page .intro-page .regard-name {
      color: var(--BRSR, #00984D);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  </style>
</head>

<body>
  <!-- <div class="page" style="background-image: url({{images.cover}});background-size: cover;padding: 32px;
  padding-bottom:0px;width: 100vw;height:100vh;">
    <div class="d-flex">
      <div>
        <div class="fw-bold" style="font-size: 1.5rem;">{{orgDetail.name}}</div>
        {{!-- <div style="font-size: 1.3rem;">Environmental, Social, and </div>
        <div style="font-size: 1.3rem;">Governance Report {{fyear}}</div> --}}
        <div style="font-size: 1.3rem;">Business Responsibility and</div>
        <div style="font-size: 1.3rem;">Sustainability Report {{fyear}}</div>
      </div>
      <img src={{orgDetail.logo}} alt="" class="ms-auto" style="height: 70%; width:12%;">
    </div>
    <img src={{images.coverImage}} alt="" class="mt-auto w-100 align-self-end" style="margin-right:-32px;">
  </div> -->

  <!-- ======== Introduction page =========== -->
  <div class="page">
    <div class="content-wrapper intro-page key-highlight-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">{Business Responsibility & Sustainability Report}</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="title mt-3">
        Introduction
      </div>
      <div class="mt-1">
        {{report name}}
      </div>
      <div class="first-para-intro mt-4">
        At Reliance Industries Limited (RIL), sustainability means building a shared future for greater success and a
        more equitable society. RIL is committed to making a positive impact on the environment, society, and the
        economy with conviction and care.
        The Business Responsibility and Sustainability Report (BRSR) exemplifies this commitment, transparently
        communicating RIL's performance to stakeholders.
      </div>
      <div class="second-para-intro mt-4">
        As a responsible corporate citizen, RIL believes in inclusive growth. The Company strives to accelerate India’s
        transition to a knowledge economy and create value for the nation by elevating the quality of life across the
        entire socio-economic spectrum. This report conforms to the Business Responsibility and Sustainability Report
        (BRSR) requirements of the Securities & Exchange Board of India (Listing Obligations and Disclosure
        Requirements) Regulations, 2015 (SEBI LODR) and the National Guidelines on Responsible Business Conduct
        (NGRBC) on Social, Environmental and Economic Responsibilities of Business released by the Ministry of Corporate
        Affairs (MCA), India.
      </div>

      <div class="dotted-line my-4"></div>
      <div class="message-text ">
        Message from the and CEO
      </div>
      <div class="second-para-intro">
        Purpose-driven growth
      </div>

      <div style="margin-top: 57px;" class="d-flex align-items-start gap-5">
        <div class="ceo-profile">
          <img src="" alt="CEO image" class="ceo-image" />
        </div>
        <div>
          <div class="second-para-intro">Dear Stakeholders </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="59" height="48" viewBox="0 0 59 48" fill="none">
            <path
              d="M26.4866 36.88C26.4866 33.92 25.6866 31.36 24.0466 29.28C21.5666 26.16 16.7266 25.76 13.9266 26.4C12.7666 19.8 18.3666 11.4 25.6066 7.8L20.0466 0C9.12665 5.2 -1.63335 17.12 0.206649 32.2C1.36665 41.72 6.88665 48 14.6866 48C18.0866 48 20.9266 47 23.1666 45C25.4066 43 26.4866 40.28 26.4866 36.88ZM58.6866 36.88C58.6866 33.92 57.8866 31.36 56.2466 29.28C53.7266 26.16 48.9266 25.76 46.1266 26.4C44.9666 19.8 50.5666 11.4 57.8066 7.8L52.2466 0C41.3266 5.2 30.6066 17.12 32.4466 32.2C33.6066 41.72 39.0866 48 46.8866 48C50.2866 48 53.1266 47 55.3666 45C57.6066 43 58.6866 40.28 58.6866 36.88Z"
              fill="url(#paint0_linear_169_1460)" />
            <defs>
              <linearGradient id="paint0_linear_169_1460" x1="58.7965" y1="2.11701e-06" x2="-6.80556" y2="35.3816"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#DDF4E5" />
                <stop offset="1" stop-color="#C1F4E6" />
              </linearGradient>
            </defs>
          </svg>
          <div class="second-para-intro mt-2">
            I am pleased to bring to you our inaugural Business Responsibility and Sustainability Report (BRSR). At JIO,
            we have long recognised the Environment, Social and Governance (ESG) stewardship as a core to our purpose.
            We have a proud legacy of pioneering positive change, not just within the industry but in the communities
            where we operate as well, and our commitment remains steadfast. As the shift to a low-carbon economy is
            likely to unfold faster, the implications on steel, a hard-to-abate sector shall be profound. We take
            cognisance of the urgency of the decarbonisation journey to meet the evolving stakeholder expectations. Our
            Net Zero target is in line with the Tata Group’s target and is the most ambitious net zero target adopted by
            any major steel company globally. We continue to seek innovative ways for this transition, through
            continuous experimentation, and investments in research, technological
          </div>

          <div class="siganture-wraaper">
            {{signature}}
          </div>
          <div class="warm-regards mt-3">
            <div class="first-para-intro">Warm Regards, </div>
            <div class="regard-name">
              Hitesh Kumar Sethia

            </div>
            <div class="regard-name">
              Chief Executive Officer & Managing Director

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>


  <!-- ===== key highlights page ===== -->
  <div class="page">
    <div class="content-wrapper key-highlight-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">{Business Responsibility & Sustainability Report}</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="title mt-3">
        Key Highlights
      </div>

      <div class="d-flex align-items-center gap-2 mt-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="182" height="38" viewBox="0 0 182 38" fill="none">
          <path d="M0.5 0.5H170C176.351 0.5 181.5 5.64873 181.5 12V26C181.5 32.3513 176.351 37.5 170 37.5H0.5V0.5Z"
            fill="#F3F0FF" stroke="#EDE8FF" />
          <text x="170" y="20" fill="#16161E" font-size="16px" font-style="normal" font-weight="500" line-height="22px"
            text-anchor="end" alignment-baseline="middle">
            PREVIOUS YEAR
          </text>
        </svg>
        <div class="year">
          {{2023-24}}
        </div>
      </div>

      <div class="d-flex gap-4 mt-4">
        <div class="headline-wraaper">
          <div class="headline">{Headline}</div>
          <div class="details mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has
            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.</div>
        </div>
        <div class="headline-wraaper">
          <div class="headline">{Headline}</div>
          <div class="details mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has
            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.</div>
        </div>
      </div>

      <div class="d-flex align-items-center gap-2 mt-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="178" height="38" viewBox="0 0 178 38" fill="none">
          <path d="M0.5 0.5H166C172.351 0.5 177.5 5.64873 177.5 12V26C177.5 32.3513 172.351 37.5 166 37.5H0.5V0.5Z"
            fill="url(#paint0_linear)" stroke="#B1F0DF" />
          <defs>
            <linearGradient id="paint0_linear" x1="178.333" y1="1.67597e-06" x2="129.592" y2="100.716"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#DDF4E5" />
              <stop offset="1" stop-color="#C1F4E6" />
            </linearGradient>
          </defs>
          <text x="166" y="20" fill="#16161E" font-size="16" font-weight="500" text-anchor="end"
            alignment-baseline="middle">
            CURRENT YEAR
          </text>
        </svg>
        <div class="year">
          {{2024-25}}
        </div>
      </div>
      <div class="d-flex gap-4 mt-4">
        <div class="headline-wraaper">
          <div class="headline">{Headline}</div>
          <div class="details mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has
            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.</div>
        </div>
        <div class="headline-wraaper">
          <div class="headline">{Headline}</div>
          <div class="details mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has
            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.</div>
        </div>
      </div>
      <div class="dotted-line my-5"></div>
      <div class="title">
        Vision, Mission & Values
      </div>

      <div class="mt-4 d-flex gap-3 align-items-center">
        <div class="image-container">
          <img src={{karbon_frontend/public/carbon-bg.png}} alt="image" />
        </div>
        <div class="headline-wraaper">
          <div class="headline">{Headline}</div>
          <div class="details mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has
            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.</div>
        </div>
      </div>

      <div class="mt-4 d-flex gap-3 align-items-center">
        <div class="headline-wraaper">
          <div class="headline">{Headline}</div>
          <div class="details mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has
            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.</div>
        </div>
        <div class="image-container">
          <img src={{karbon_frontend/public/carbon-bg.png}} alt="image" />
        </div>

      </div>

    </div>
  </div>

  <div class="page">
    <div class="content-wrapper index-page">
      <div class="main-title mb-0">CONTENTS</div>
      <div class="content-subtitle">[As per Regulation 34(2)(f) of SEBI (Listing Obligations and Disclosure
        Requirements) Regulations, 2015]</div>
      <div class="content">
        <div>
          <div class="section">
            <div class="section-title">
              <span style="color: #16161EB2; font-size: 16px; font-style: normal; font-weight: 400;">Section A:</span>
              General Disclosures
            </div>
            <div class="dotted-line"></div>
            <div class="page-number">3</div>
          </div>
          <ul style="padding-left: 0rem !important;list-style: none;" class="mt-2">
            <li>
              <div class="section sub-section">
                <div class="section-title">i. Details of the listed entity</div>
                <div class="dotted-line"></div>
                <div class="page-number">3</div>
              </div>
            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">ii. Products/services</div>
                <div class="dotted-line"></div>
                <div class="page-number">3</div>
              </div>
            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">iii. Operations</div>
                <div class="dotted-line"></div>
                <div class="page-number">3</div>
              </div>
            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">iv. Employees</div>
                <div class="dotted-line"></div>
                <div class="page-number">3</div>
              </div>
            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">v. Holding, Subsidiary and Associate Companies (including joint ventures)
                </div>
                <div class="dotted-line"></div>
                <div class="page-number">3</div>
              </div>
            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">vi. CSR Details</div>
                <div class="dotted-line"></div>
                <div class="page-number">3</div>
              </div>
            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">vii. Transparency and Disclosures Compliances</div>
                <div class="dotted-line"></div>
                <div class="page-number">3</div>
              </div>
            </li>

          </ul>
        </div>

        <div class="mt-4">
          <div class="section">
            <div class="section-title"> <span
                style="color: #16161EB2; font-size: 16px; font-style: normal; font-weight: 400;">Section B:</span>
              Management and process disclosures</div>
            <div class="dotted-line"></div>
            <div class="page-number">7</div>
          </div>
          <ul style="padding-left: 0rem !important;list-style: none;" class="mt-2">
            <li>
              <div class="section sub-section">
                <div class="section-title">Policy & management processes</div>
                <div class="dotted-line"></div>
                <div class="page-number">7</div>
              </div>
            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">Governance, leadership & oversight</div>
                <div class="dotted-line"></div>
                <div class="page-number">7</div>
              </div>
            </li>


          </ul>
        </div>

        <div class="mt-4">
          <div class="section mb-2">
            <div class="section-title"> <span
                style="color: #16161EB2; font-size: 16px; font-style: normal; font-weight: 400;">Section C:</span>
              Principle-wise performance disclosures</div>
            <div class="dotted-line"></div>
            <div class="page-number">14</div>
          </div>
          <ul style="padding-left: 0rem !important;list-style: none;">
            <li>
              <div class="section sub-section">
                <div class="section-title">P1: Businesses should conduct and govern themselves with integrity, and in a
                  manner that is ethical,
                  transparent and accountable.</div>
                <div class="dotted-line"></div>
                <div class="page-number">14</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P2: Businesses should provide goods and services in a manner that is
                  sustainable and safe</div>
                <div class="dotted-line"></div>
                <div class="page-number">17</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P3: Businesses should respect and promote the well-being of all employees,
                  including those in their value
                  chains</div>
                <div class="dotted-line"></div>
                <div class="page-number">19</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P4: Businesses should respect the interests of and be responsive to all its
                  stakeholders</div>
                <div class="dotted-line"></div>
                <div class="page-number">29</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P5: Businesses should respect and promote human rights</div>
                <div class="dotted-line"></div>
                <div class="page-number">32</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P6: Businesses should respect and make efforts to protect and restore the
                  environment</div>
                <div class="dotted-line"></div>
                <div class="page-number">36</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P7: Businesses, when engaging in influencing public and regulatory policy,
                  should do so in a manner that is
                  responsible and transparent</div>
                <div class="dotted-line"></div>
                <div class="page-number">42</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P8: Businesses should promote inclusive growth and equitable development
                </div>
                <div class="dotted-line"></div>
                <div class="page-number">43</div>
              </div>

            </li>
            <li>
              <div class="section sub-section">
                <div class="section-title">P9: Businesses should engage with and provide value to their consumers in a
                  responsible
                  manner</div>
                <div class="dotted-line"></div>
                <div class="page-number">45</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- about page section A -->

  <div class="page">
    <div class="content-wrapper about-page" style="height:100vh;">
      <div class="mt-3 d-flex align-items-baseline ">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
        <div>
          <div class="section-name">
            Section a:
          </div>
          <div class="section-title">
            General Disclosure
          </div>
          <div class="section-info">
            This section contains an overview of the business, including markets served, financial performance, key
            employee statistics and mapping of risks and opportunities.
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class=""
        style="color: rgba(22, 22, 30, 0.70);  font-size: 14px; font-style: normal; font-weight: 500; line-height: 20px; text-transform: uppercase;">
        Section A:</div>
      <div class="main-title mt-2">General Disclosures</div>
      <div class="section-title d-flex align-items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#50CFAB" />
          <text x="16" y="18" font-family="Arial" font-size="16" fill="white" text-anchor="middle"
            alignment-baseline="middle">i</text>
        </svg>
        Details of the listed entity
      </div>
      <table class="table mt-3" style="table-layout: unset;">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td class="header-column">Corporate Identity Number (CIN) of the Company:</td>
            <td class="value-column">{{data.SectionA.corporateNumber}}</td>
          </tr>
          <tr>
            <td>2</td>
            <td class="header-column">Name of the Listed Entity:</td>
            <td class="value-column">{{data.SectionA.listedEntityName}}</td>
          </tr>
          <tr>
            <td>3</td>
            <td class="header-column">Year of incorporation:</td>
            <td class="value-column">{{data.SectionA.yearOfInc}}</td>
          </tr>
          <tr>
            <td>4</td>
            <td class="header-column">Registered office address:</td>
            <td class="value-column">{{data.SectionA.officeAddress}}</td>
          </tr>
          <tr>
            <td>5</td>
            <td class="header-column">Corporate address:</td>
            <td class="value-column">{{data.SectionA.corporateAddress}}</td>
          </tr>
          <tr>
            <td>6</td>
            <td class="header-column">E-mail:</td>
            <td class="value-column">{{data.SectionA.email}}</td>
          </tr>
          <tr>
            <td>7</td>
            <td class="header-column">Telephone:</td>
            <td class="value-column">
              {{data.SectionA.telephone.countryCode}} {{data.SectionA.telephone.areaCode}}
              {{data.SectionA.telephone.mobileNo}} {{data.SectionA.telephone.telephoneNo}}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td class="header-column">Website:</td>
            <td class="value-column">{{data.SectionA.website}}</td>
          </tr>
          <tr>
            <td>9</td>
            <td class="header-column">Financial year for which reporting is being done:</td>
            <td class="value-column">{{data.SectionA.financialYear}}</td>
          </tr>
          <tr>
            <td>10</td>
            <td class="header-column">Name of the Stock Exchange(s) where shares are listed:</td>
            <td class="value-column">{{data.SectionA.stockExchange}}</td>
          </tr>
          <tr>
            <td>11</td>
            <td class="header-column">Paid-up Capital:</td>
            <td class="value-column">{{data.SectionA.paidUpCapital}}</td>
          </tr>
          <tr>
            <td>12</td>
            <td class="header-column">Name and contact details (telephone, email address) of the person who may be
              contacted in case of any queries on the BRSR report:</td>
            <td class="value-column">
              <div>{{data.SectionA.contactDetail.name}}</div>
              <div>
                {{data.SectionA.contactDetail.countryCode}} {{data.SectionA.contactDetail.areaCode}}
                {{data.SectionA.contactDetail.mobileNo}} {{data.SectionA.contactDetail.telephoneNo}}
              </div>
              <div>{{data.SectionA.contactDetail.email}}</div>
            </td>
          </tr>
          <tr>
            <td>13</td>
            <td class="header-column">Reporting boundary:</td>
            <td class="value-column">{{data.SectionA.reportingBoundary}}</td>
          </tr>
          <tr>
            <td>14</td>
            <td class="header-column">Name of assurance provider:</td>
            <td class="value-column">{{data.SectionA.nameOfAssurance}}</td>
          </tr>
          <tr>
            <td>15</td>
            <td class="header-column">Type of assurance obtained:</td>
            <td class="value-column">{{data.SectionA.typeOfAssurance}}</td>
          </tr>
        </tbody>
      </table>


    </div>
  </div>

  <div class="page">
    <div class="content-wrapper entity-market-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="section-title d-flex align-items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#50CFAB" />
          <text x="16" y="18" font-family="Arial" font-size="16" fill="white" text-anchor="middle"
            alignment-baseline="middle">ii</text>
        </svg>
        Products/Services
      </div>
      <div class="table-title">16. Details of business activities (accounting for 90% of the turnover):</div>

      <table class="table mt-2" style="table-layout: unset;">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Description of main activity</th>
            <th>Description of business activity</th>
            <th style="text-align: right;">% of turnover of the Company</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>{{item.mainActivity}}</td>
            <td>{{item.businessActivity}}</td>
            <td style="text-align: right;">{{returnAlternateValue item.percentTurnover "percent"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title">17. Products/Services sold by the entity (accounting for 90% of the entity's Turnover):
      </div>

      <table class="table mt-2" style="table-layout: unset;">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Product/Service</th>
            <th>NIC Code</th>
            <th style="text-align: right;">% of turnover of the Company</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>{{item.productService}}</td>
            <td>{{item.nicCode}}</td>
            <td style="text-align: right;">{{returnAlternateValue item.percentTurnover "percent"}}</td>
          </tr>

        </tbody>
      </table>

      <div class="section-title mt-5 d-flex align-items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#50CFAB" />
          <text x="16" y="18" font-family="Arial" font-size="16" fill="white" text-anchor="middle"
            alignment-baseline="middle">iii</text>
        </svg>
        Operations
      </div>
      <div class="table-title">
        18. Number of locations where plants and/or operations/offices of the entity are situated:
      </div>

      <table class="table mt-2">
        <thead>
          <tr>
            <th>Location</th>
            <th style="text-align: right;">Number of plants</th>
            <th style="text-align: right;">Number of offices</th>
            <th style="text-align: right;">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>National</td>
            <td style="text-align: right;">{{returnAlternateValue data.SectionA.locationOfPlants.national.noOfPlants
              "number"}}</td>
            <td style="text-align: right;">{{returnAlternateValue data.SectionA.locationOfPlants.national.noOfOffices
              "number"}}</td>
            <td style="text-align: right;">{{returnAlternateValue data.SectionA.locationOfPlants.national.total
              "number"}}</td>
          </tr>
          <tr>
            <td>International</td>
            <td style="text-align: right;">{{returnAlternateValue
              data.SectionA.locationOfPlants.international.noOfPlants "number"}}</td>
            <td style="text-align: right;"> {{returnAlternateValue
              data.SectionA.locationOfPlants.international.noOfOffices "number"}}</td>
            <td style="text-align: right;">{{returnAlternateValue data.SectionA.locationOfPlants.international.total
              "number"}}</td>
          </tr>
        </tbody>
      </table>
      <div class="table-title mb-0">19. Markets served by the entity:</div>
      <div class="ms-3">
        <div class="table-title">a. Number of Locations</div>

        <table class="table mt-2">
          <thead>
            <tr>
              <th>Locations</th>
              <th style="text-align: right;">Number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>National (No. of States)</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.marketServed.national.number
                "number"}}</td>
            </tr>
            <tr>
              <td>International (No. of Countries)</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.marketServed.international.number
                "number"}}</td>
            </tr>
          </tbody>
        </table>
        <div class="table-title mb-1 mt-4">
          b. What is the contribution of exports as a percentage of the total turnover of the entity?
        </div>
        <div class="normal-input">
          {{returnAlternateValue data.SectionA.exportContribution
          "percent"}}
        </div>
        <div class="table-title mb-1 mt-4">
          c. A brief on types of customers
        </div>
        <div class="normal-input">{{data.SectionA.typesOfCustomer}}</div>
      </div>
      <div class="section-title mt-5 d-flex align-items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#50CFAB" />
          <text x="16" y="18" font-family="Arial" font-size="16" fill="white" text-anchor="middle"
            alignment-baseline="middle">iv</text>
        </svg>
        Employees
      </div>
      <div class="table-title mt-2">20. Details as at the end of Financial Year:</div>
      <div class="ms-3">
        <div class="table-title mt-2">a. Employees and workers (including differently abled):</div>

        <table class="table mt-2">
          <thead>
            <tr>
              <th rowspan="2" class="text-center">Sr. No.</th>
              <th rowspan="2" class="text-start">Particulars</th>
              <th rowspan="2" class="text-end">Total (A)</th>
              <th colspan="2" class="text-center">Male</th>
              <th colspan="2" class="text-center">Female</th>
            </tr>
            <tr>
              <th style="text-align: right;" class="rowspan-border">No. (B)</th>
              <th style="text-align: right;">% (B/A)</th>
              <th style="text-align: right;">No. (C)</th>
              <th style="text-align: right;">% (C/A)</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td></td>
              <td colspan="6" class="text-start fw-semibold" style="color: black;text-transform: uppercase;">
                Employees
              </td>
            </tr>
            <tr>
              <td class="text-center">1.</td>
              <td>Permanent (D)</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.permanent.total "number"}}
              </td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.permanent.male "number"}}
              </td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.permanent.malePercent
                "percent"}}</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.permanent.female "number"}}
              </td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.permanent.femalePercent
                "percent"}}</td>
            </tr>
            <tr>
              <td class="text-center">2.</td>
              <td>Other than Permanent (E)</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.notPermanent.total "number"}}
              </td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.notPermanent.male "number"}}
              </td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.notPermanent.malePercent
                "percent"}}</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.notPermanent.female
                "number"}}</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.notPermanent.femalePercent
                "percent"}}</td>
            </tr>
            <tr>
              <td class="text-center">3.</td>
              <td class="fw-semibold">Total employees (D + E)</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.totalEmployee.total
                "number"}}</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.totalEmployee.male "number"}}
              </td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.totalEmployee.malePercent
                "percent"}}</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.totalEmployee.female
                "number"}}</td>
              <td style="text-align: right;">{{returnAlternateValue data.SectionA.employee.totalEmployee.femalePercent
                "percent"}}</td>
            </tr>
            <tr>
              <td></td>
              <td colspan="6" class="text-start fw-semibold" style="color: black;text-transform: uppercase;">
                WORKERS
              </td>
            </tr>
            <tr>
              <td class="text-center">1.</td>
              <td>Permanent (F)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.permanent.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.permanent.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.permanent.malePercent "percent"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.permanent.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.permanent.femalePercent "percent"}}</td>
            </tr>
            <tr>
              <td class="text-center">2.</td>
              <td>Other than Workers (G)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.notPermanent.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.notPermanent.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.notPermanent.malePercent "percent"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.notPermanent.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.notPermanent.femalePercent "percent"}}
              </td>
            </tr>
            <tr>
              <td class="text-center">3.</td>
              <td class="fw-semibold">Total workers (F + G)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.totalEmployee.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.totalEmployee.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.totalEmployee.malePercent "percent"}}
              </td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.totalEmployee.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.worker.totalEmployee.femalePercent "percent"}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ms-3">
        <div class="table-title mb-0">b. Differently abled Employees and workers:</div>

        <table class="table mt-2">
          <thead>
            <tr>
              <th rowspan="2" class="text-center">Sr. No.</th>
              <th rowspan="2" class="text-start">Particulars</td>
              <th rowspan="2" class="text-end">Total (A)</th>
              <th colspan="2" class="text-center">Male</th>
              <th colspan="2" class="text-center">Female</th>
            </tr>
            <tr>
              <th class="text-end rowspan-border">No. (B)</th>
              <th class="text-end">% (B/A)</th>
              <th class="text-end">No. (B)</th>
              <th class="text-end">% (B/A)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td colspan="6" class="text-start fw-semibold " style="color: black;text-transform: uppercase;">
                DIFFERENTLY ABLED EMPLOYEES
              </td>
            </tr>
            <tr>
              <td class="text-center">1.</td>
              <td>Permanent (D)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.permanent.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.permanent.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.permanent.malePercent "percent"}}
              </td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.permanent.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.permanent.femalePercent "percent"}}
              </td>
            </tr>
            <tr>
              <td class="text-center">2.</td>
              <td>Other than Permanent (E)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.notPermanent.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.notPermanent.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.notPermanent.malePercent
                "percent"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.notPermanent.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.notPermanent.femalePercent
                "percent"}}</td>
            </tr>
            <tr>
              <td class="text-center">3.</td>
              <td class="fw-semibold">Total employees (D + E)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.totalEmployee.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.totalEmployee.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.totalEmployee.malePercent
                "percent"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.totalEmployee.female "number"}}
              </td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffEmployee.totalEmployee.femalePercent
                "percent"}}</td>
            </tr>
            <tr>
              <td></td>
              <td colspan="6" class="text-start fw-semibold" style="color: black;text-transform: uppercase;">
                DIFFERENTLY ABLED WORKERS
              </td>
            </tr>
            <tr>
              <td class="text-center">1.</td>
              <td>Permanent (F)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.permanent.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.permanent.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.permanent.malePercent "percent"}}
              </td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.permanent.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.permanent.femalePercent "percent"}}
              </td>
            </tr>
            <tr>
              <td class="text-center">2.</td>
              <td>Other than Workers (G)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.notPermanent.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.notPermanent.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.notPermanent.malePercent "percent"}}
              </td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.notPermanent.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.notPermanent.femalePercent
                "percent"}}</td>
            </tr>
            <tr>
              <td class="text-center">3.</td>
              <td class="fw-semibold">Total workers (F + G)</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.totalEmployee.total "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.totalEmployee.male "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.totalEmployee.malePercent "percent"}}
              </td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.totalEmployee.female "number"}}</td>
              <td class="text-end">{{returnAlternateValue data.SectionA.diffWorker.totalEmployee.femalePercent
                "percent"}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-title mt-4">21. Participation/Inclusion/Representation of women:</div>
      <table class="table mt-2">
        <thead>
          <tr>
            <th rowspan="2" class="text-center">S. No</th>
            <th rowspan="2"></th>
            <th rowspan="2" class="text-end">Total (A)</th>
            <th colspan="2" class="text-center">No. and percentage of Females</th>
          </tr>
          <tr>
            <th class="text-end rowspan-border">No. (B)</th>
            <th class="text-end">% (B/A)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">1</td>
            <td>Board of Directors</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.participation.boardOfDirectors.total "number"}}
            </td>
            <td class="text-end">{{returnAlternateValue data.SectionA.participation.boardOfDirectors.women "number"}}
            </td>
            <td class="text-end">{{data.SectionA.participation.boardOfDirectors.percentage}}%</td>
          </tr>
          <tr>
            <td class="text-center">2</td>
            <td>Key Management Personnel</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.participation.keyManagement.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.participation.keyManagement.women "number"}}</td>
            <td class="text-end">{{data.SectionA.participation.keyManagement.percentage}}%</td>
          </tr>
        </tbody>
      </table>
      <div class="table-title mt-4">22. Turnover rate for permanent employees (Disclose trends for the past 3 years):
      </div>

      <table class="table mt-2">
        <thead>
          <tr>
            <th rowspan="2" class="text-center">S.No.</th>
            <th rowspan="2"></th>
            <th colspan="3">
              FY {{fyear}}
              (Turnover rate in current FY)
            </th>
            <th colspan="3">
              FY {{previousYear}}
              (Turnover rate in previous FY)
            </th>
            <th colspan="3">
              FY {{prePreviousYear}}
              (Turnover rate in the year prior to the previous FY)
            </th>
          </tr>
          <tr>
            <th class="text-end rowspan-border">Male</th>
            <th class="text-end">Female</th>
            <th class="text-end">Total</th>
            <th class="text-end">Male</th>
            <th class="text-end">Female</th>
            <th class="text-end">Total</th>
            <th class="text-end">Male</th>
            <th class="text-end">Female</th>
            <th class="text-end">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">1</td>
            <td>Permanent Employees</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverCurrent.permanentEmployee.male "percent"}}
            </td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverCurrent.permanentEmployee.female
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverCurrent.permanentEmployee.total
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrevious.permanentEmployee.male
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrevious.permanentEmployee.female
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrevious.permanentEmployee.total
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrePrevious.permanentEmployee.male
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrePrevious.permanentEmployee.female
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrePrevious.permanentEmployee.total
              "percent"}}</td>
          </tr>
          <tr>
            <td class="text-center">2</td>
            <td>Permanent Workers</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverCurrent.permanentWorker.male "percent"}}
            </td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverCurrent.permanentWorker.female "percent"}}
            </td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverCurrent.permanentWorker.total "percent"}}
            </td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrevious.permanentWorker.male "percent"}}
            </td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrevious.permanentWorker.female
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrevious.permanentWorker.total "percent"}}
            </td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrePrevious.permanentWorker.male
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrePrevious.permanentWorker.female
              "percent"}}</td>
            <td class="text-end">{{returnAlternateValue data.SectionA.turnOverPrePrevious.permanentWorker.total
              "percent"}}</td>
          </tr>
        </tbody>
      </table>
      <div class="section-title d-flex align-items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#50CFAB" />
          <text x="16" y="18" font-family="Arial" font-size="16" fill="white" text-anchor="middle"
            alignment-baseline="middle">v</text>
        </svg>
        Holding, Subsidiary and Associate Companies (including joint ventures)
      </div>
      <div class="table-title">23.(a) Names of holding / subsidiary / associate companies / joint ventures</div>

      <table class="table mt-2" style="text-wrap: wrap;">
        <thead>
          <tr>
            <th class="text-center">Sr. No.</th>
            <th>Name of the holding / subsidiary / associate companies / joint ventures (A)</th>
            <th>Indicate whether holding/ Subsidiary/ Associate/ Joint Venture</th>
            <th class="text-end">% of shares held by listed entity</th>
            <th>Does the entity indicated at column A, participate in the Business Responsibility initiatives of the
              listed entity? (Yes/No)</td>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionA.holdings as |item index|}}
          <tr>
            <td class="text-center">{{addNumber @index 1}}.</td>
            <td>{{item.companyDetail}}</td>
            <td>{{item.indicator}}</td>
            <td class="text-end">{{item.sharePercent}}%</td>
            <td class="text-center">{{item.participation}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
      <div class="section-title d-flex align-items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#50CFAB" />
          <text x="16" y="18" font-family="Arial" font-size="16" fill="white" text-anchor="middle"
            alignment-baseline="middle">vi</text>
        </svg>
        CSR Details
      </div>
      <div class="section-title my-3">24. Corporate Social Responsibility Details</div>
      <div class="normal-input">
        (i) Whether CSR is applicable as per section 135 of Companies Act, 2013: (Yes/No):
      </div>
      <div class="gray-border-input">
        {{data.SectionA.csrApplicable}}
      </div>
      <div class="gray-border-input">(ii) Turnover (in Rs.):
      </div>
      <div class="gray-border-input">
        {{returnAlternateValue data.SectionA.turnOver "number"}}
      </div>
      <div class="gray-border-input">(iii) Net worth (in Rs.):
      </div>
      <div class="gray-border-input">
        {{returnAlternateValue data.SectionA.netWorth "number"}}
      </div>
      <div class="section-title mt-4 d-flex align-items-center gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#50CFAB" />
          <text x="16" y="18" font-family="Arial" font-size="16" fill="white" text-anchor="middle"
            alignment-baseline="middle">vii</text>
        </svg>
        Transparency and Disclosures Compliances
      </div>
      <div class="table-title">
        25. Complaints/Grievances on any of the principles (Principles 1 to 9) under the National Guidelines on
        Responsible Business Conduct:
      </div>
      <div>
        a. Employees and workers (including differently abled):
      </div>
      <table class="table mt-2">
        <thead>
          <tr>
            <th rowspan="2">Stakeholder group from whom complaint is received</th>
            <th colspan="1">Grievance Redressal Mechanism in Place (Yes/No)</th>
            <th colspan="3" class="text-center">FY {{fyear}} Current Financial Year</th>
            <th colspan="3" class="text-center">FY {{previousYear}} Previous Financial Year</th>
          </tr>
          <tr>
            <th class="rowspan-border">(If Yes, then provide web-link for grievance redress policy)</th>
            <th class="text-end">Number of complaints filed during the year</th>
            <th class="text-end">Number of complaints pending resolution at close of the year</th>
            <th>Remarks</th>
            <th class="text-end">Number of complaints filed during the year</th>
            <th class="text-end">Number of complaints pending resolution at close of the year</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionA.complaints}}
          <tr>
            <td>{{stakeholder}}</td>
            <td>{{grievanceRedressal}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.totalComplaints "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.pendingComplaints "number"}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.totalComplaints "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.pendingComplaints "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
      <div class="table-title">
        26. Overview of the entity’s material responsible business conduct issues
      </div>
      <div class="table-sub-title my-2">
        Please indicate material responsible business conduct and sustainability issues pertaining to environmental and
        social matters that present a risk or an opportunity to your business, rationale for identifying the same,
        approach to adapt or mitigate the risk along-with its financial implications, as per the following format
      </div>

      <table class="table mt-2">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Material issue identified</th>
            <th>Indicate whether risk or opportunity(R/O)</th>
            <th>Rationale for identifying the risk / opportunity</th>
            <th>In case of risk, approach to adapt or mitigate</th>
            <th>Financial implications of the risk or opportunity (Indicate positive or negative implications)</th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionA.riskBusinessIssue as |item index|}}
          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>{{item.materialIssue}}</td>
            <td>{{item.riskOrOpportunity}}</td>
            <td>{{item.reasonOfRiskOpportunity}}</td>
            <td>{{item.mitigation}}</td>
            <td>{{item.financialImplication}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
    </div>
  </div>

  <!-- about page section B -->

  <div class="page">
    <div class="content-wrapper about-page section-b-about" style="height:100vh;">
      <div class="mt-3 d-flex align-items-baseline ">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
        <div>
          <div class="section-name">
            Section b:
          </div>
          <div class="section-title">
            Management and Process Disclosures
          </div>
          <div class="section-info">
            This section is aimed at helping businesses demonstrate the structures, policies, and processes put in place
            towards adopting the NGRBC Principles and Core Elements.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page">
    <div class="content-wrapper">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class=""
        style="color: rgba(22, 22, 30, 0.70);  font-size: 14px; font-style: normal; font-weight: 500; line-height: 20px; text-transform: uppercase;">
        Section B:</div>
      <div class="main-title mb-2">Management and Process Disclosures</div>

      <table class="table-sectionB mt-3" style="table-layout: unset;">
        <thead>
          <tr>
            <th class="" colspan="2">
              <div class="d-flex gap-2 align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
                    fill="#DC96C2" />
                  <path d="M10 16H22M22 16L17 21M22 16L17 11" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

                <div>
                  Disclosure Questions
                </div>
              </div>
            </th>
            <th>P1</th>
            <th>P2</th>
            <th>P3</th>
            <th>P4</th>
            <th>P5</th>
            <th>P6</th>
            <th>P7</th>
            <th>P8</th>
            <th>P9</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-start fw-semibold text-black" colspan="10">
              Policy and Management Processes
            </td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">1a.</td>
            <td class="text-start fw-semibold text-black">Whether your entity's policy/policies cover each principle and
              its core elements of the NGRBCs.
              (Yes/No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">1b.</td>
            <td class="text-start fw-semibold text-black">Has the policy been approved by the Board?
              (Yes/No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>

          <tr>
            <td class="text-center fw-semibold text-black">1c.</td>
            <td class="text-start fw-semibold text-black">Web Link of the Policies, if available</td>
            <td colspan="9">{{wb link}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">2</td>
            <td class="text-start fw-semibold text-black">Whether the entity has translated the policy into procedures.
              (Yes / No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">3</td>
            <td class="text-start fw-semibold text-black">Do the enlisted policies extend to your value chain partners?
              (Yes/No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">4.</td>
            <td class="text-start fw-semibold text-black">Name of the national and international codes/
              certifications/labels/ standards (e.g., Forest Stewardship Council, Fairtrade, Rainforest Alliance,
              Trustee) standards (e.g., SA 8000, OHSAS, ISO, BIS) adopted by your entity and mapped to each principle.
            </td>
            <td colspan="9">{{content}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">5.</td>
            <td class="text-start fw-semibold text-black">Specific commitments, goals and targets set by the entity with
              defined timelines, if any</td>
            <td colspan="9">{{content}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">6.</td>
            <td class="text-start fw-semibold text-black">Key Performance targets across ESG parameters are set
              internally and monitored and acted upon continuously.</td>
            <td colspan="9">{{content}}</td>
          </tr>
          <tr>
            <td class="text-start fw-semibold text-black" colspan="10">
              Governance, leadership and oversight
            </td>
          </tr>

          <tr>
            <td class="text-center fw-semibold text-black">7.</td>
            <td class="text-start fw-semibold text-black">Statement by director responsible for the business
              responsibility report, highlighting ESG related challenges, targets and achievements (listed entity has
              flexibility regarding the placement of this disclosure)</td>
            <td colspan="9"> {{data.SectionB.statementByDirector}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">8.</td>
            <td class="text-start fw-semibold text-black">Details of the highest authority responsible for
              implementation and oversight of the Business Responsibility policy (ies).</td>
            <td colspan="9"> {{data.SectionB.highestAuthorityDetails}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">9.</td>
            <td class="text-start fw-semibold text-black">Does the entity have a specified Committee of the Board/
              Director responsible for decision making on sustainability related issues? (Yes / No). If yes, provide
              details.</td>
            <td colspan="9"> {{data.SectionB.hasSpecifiedCommittee}}</td>
          </tr>
        </tbody>
      </table>

      <table class="table-sectionB mt-4" style="table-layout: unset;">
        <thead>
          <tr>
            <th class="" colspan="2">
              <div class="d-flex gap-2 align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
                    fill="#DC96C2" />
                  <path d="M10 16H22M22 16L17 21M22 16L17 11" stroke="white" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

                <div>
                  Disclosure Questions
                </div>
              </div>
            </th>
            <th>P1</th>
            <th>P2</th>
            <th>P3</th>
            <th>P4</th>
            <th>P5</th>
            <th>P6</th>
            <th>P7</th>
            <th>P8</th>
            <th>P9</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center fw-semibold text-black">10</td>
            <td class="text-start fw-semibold text-black" colspan="10">
              Details of Review of NGRBCs by the Company
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-start text-black" style="font-weight: 500;">Subject of Review</td>
            <td class="text-start fw-semibold text-black" colspan="8">Indicate whether review was undertaken by
              Director/Committee of the Board/ Any other Committee</td>
          </tr>
          <tr>
            <td></td>
            <td>Performance against above policies and follow up action</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td></td>
            <td>Compliance with statutory requirements of relevance to the principles, and, rectification of
              any non-compliances</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td></td>
            <td class="text-start fw-semibold text-black">Subject of Review</td>
            <td class="text-start fw-semibold text-black" colspan="8">Frequency (Annually/ Half yearly/ Quarterly/ Any
              other – please specify)</td>
          </tr>
          <tr>
            <td></td>
            <td>Performance against above policies and follow up action</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td></td>
            <td>Compliance with statutory requirements of relevance to the principles, and, rectification of
              any non-compliances</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>

          <tr>
            <td class="text-center fw-semibold text-black">11</td>
            <td class="text-start fw-semibold text-black">
              Has the entity carried out independent assessment/evaluation of the working of its policies by an external
              agency? (Yes/No). If yes, provide name of the agency.
            </td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td class="text-center fw-semibold text-black">12</td>
            <td class="text-start fw-semibold text-black">
              If answer to question (1) above is "No" i.e. not all Principles are covered by a policy, reasons to be
              stated:
            </td>
            <td colspan="9"></td>
          </tr>
          <tr>
            <td></td>
            <td>The entity does not consider the Principles material to its business (Yes/No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td></td>
            <td>The entity is not at a stage where it is in a position to formulate and implement the
              policies on specified principles (Yes/No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td></td>
            <td>The entity does not have the financial or/human and technical resources available for the
              task (Yes/No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td></td>
            <td>It is planned to be done in the next financial year (Yes/No)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
          <tr>
            <td></td>
            <td>Any other reason (please specify)</td>
            <td>{{p1}}</td>
            <td>{{p2}}</td>
            <td>{{p3}}</td>
            <td>{{p4}}</td>
            <td>{{p5}}</td>
            <td>{{p6}}</td>
            <td>{{p7}}</td>
            <td>{{p8}}</td>
            <td>{{p9}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>


  <!-- about page section c -->
  <!-- <div class="page">
    <div class="content-wrapper about-page section-c-about" style="height:100vh;">
      <div class="mt-3 d-flex align-items-baseline ">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>

      <div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
        <div>
          <div class="section-name">
            Section c:
          </div>
          <div class="section-title">
            Principle-Wise Performance Disclosure
          </div>
          <div class="section-info">
            This section is aimed at helping entities demonstrate their performance in integrating the Principles and
            Core Elements with key processes and decisions. The information sought is categorised as “Essential” and
            “Leadership”. While the essential indicators are expected to be disclosed by every entity that is mandated
            to file this report, the leadership indicators may be voluntarily disclosed by entities which aspire to
            progress to a higher level in their quest to be socially, environmentally and ethically responsible.
          </div>
        </div>
      </div>


    </div>
  </div> -->


  <div class="page mt-2">
    <div class="content-wrapper">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class=""
        style="color: rgba(22, 22, 30, 0.70);  font-size: 14px; font-style: normal; font-weight: 500; line-height: 20px; text-transform: uppercase;">
        Section: C</div>
      <div class="main-title mb-2">Principle-Wise Performance Disclosure</div>
      <div class="principle-wrapper">
        <div class="principle-title">
          PRINCIPLE-1:
        </div>
        <div class="principle-description">
          BUSINESSES SHOULD CONDUCT AND GOVERN THEMSELVES WITH
          INTEGRITY, AND IN A MANNER THAT IS ETHICAL, TRANSPARENT AND ACCOUNTABLE.
        </div>
      </div>
      <div class="table-sub-title mt-3">ESSENTIAL INDICATORS</div>
      <div class="table-title">
        1. Percentage coverage by training and awareness programmes on any of the Principles during the financial year:
      </div>

      <table class="table-sectionC  mt-3">
        <thead>
          <tr>
            <th>Segment</th>
            <th class="text-end">Total number of training and awareness programmes held</th>
            <th>Topics / principles covered under the training and its impact</td>
            <th class="text-end">%age of persons in respective category covered by the awareness programmes</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Board of Directors</td>
            <td class="text-end">{{returnAlternateValue totalPrograms "number"}}</td>
            <td>{{principlesCovered}}</td>
            <td class="text-end">{{returnAlternateValue peopleCovered "percent"}}</td>
          </tr>
          <tr>
            <td>Key Managerial Personnel</td>
            <td class="text-end">{{returnAlternateValue totalPrograms "number"}}</td>
            <td>{{principlesCovered}}</td>
            <td class="text-end">{{returnAlternateValue peopleCovered "percent"}}</td>
          </tr>
          <tr>
            <td>Employees other than BoD and KMPs</td>
            <td class="text-end">{{returnAlternateValue totalPrograms "number"}}</td>
            <td>{{principlesCovered}}</td>
            <td class="text-end">{{returnAlternateValue peopleCovered "percent"}}</td>
          </tr>
          <tr>
            <td>Workers</td>
            <td class="text-end">{{returnAlternateValue totalPrograms "number"}}</td>
            <td>{{principlesCovered}}</td>
            <td class="text-end">{{returnAlternateValue peopleCovered "percent"}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title mt-3">
        2. Details of fines/ penalties /punishment/ award/ compounding fees/ settlement amount paid in proceedings (by
        the entity or by Directors/ KMPs) with regulators/ law enforcement agencies/ judicial institutions, in the
        financial year, in the following format
      </div>
      <div class="table-sub-title my-2">
        Note: the entity shall make disclosures on the basis of materiality as specified in Regulation 30 of SEBI
        (Listing Obligations and Disclosure Obligations) Regulations, 2015 and as disclosed on the entity’s website
      </div>

      <table class="table-sectionC mb-0">
        <thead>
          <tr>
            <th colspan="6" class="fs-semibold text-center text-black">Monetary</th>
          </tr>
          <tr>
            <th></th>
            <th>NGRBC Principle</th>
            <th>Name of the regulatory/ enforcement</th>
            <th>
              Amount (In INR)
            </th>
            <th>Brief of the Case</th>
            <th>Has an appeal been preferred? (Yes/No)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Penalty/ Fine</td>
            <td>{{ngrbcPrinciple}}</td>
            <td>{{regulatoryName}}</td>
            <td>{{amount}}</td>
            <td>{{caseBrief}}</td>
            <td>{{appealPreffered}}</td>
          </tr>
          <tr>
            <td>Settlement</td>
            <td>{{ngrbcPrinciple}}</td>
            <td>{{regulatoryName}}</td>
            <td>{{amount}}</td>
            <td>{{caseBrief}}</td>
            <td>{{appealPreffered}}</td>
          </tr>
          <tr>
            <td>Compounding fee</td>
            <td>{{ngrbcPrinciple}}</td>
            <td>{{regulatoryName}}</td>
            <td>{{amount}}</td>
            <td>{{caseBrief}}</td>
            <td>{{appealPreffered}}</td>
          </tr>
        </tbody>
      </table>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th colspan="5" class="fs-semibold text-center text-black">Non-Monetary</th>
          </tr>
          <tr>
            <th></th>
            <th>NGRBC Principle</th>
            <th>Name of the regulatory/ enforcement</th>
            <th>Brief of the Case</th>
            <th>Has an appeal been preferred? (Yes/No)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Imprisonment</td>
            <td>{{ngrbcPrinciple}}</td>
            <td>{{regulatoryName}}</td>
            <td>{{caseBrief}}</td>
            <td>{{appealPreffered}}</td>
          </tr>
          <tr>
            <td>Punishment</td>
            <td>{{ngrbcPrinciple}}</td>
            <td>{{regulatoryName}}</td>
            <td>{{caseBrief}}</td>
            <td>{{appealPreffered}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title">
        3. Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases where
        monetary or non-monetary action has been appealed.
      </div>
      <div class="table-sub-title my-2">
        Appeal has been filed before the Securities Appellate Tribunal (“SAT”) against the order passed by the
        Adjudicating Officer of SEBI on June 20, 2022 imposing a penalty of C30 lakh. SAT has stayed the operation of
        the order dated June 20, 2022 and appeal is pending.
      </div>

      <table class="table-sectionC">
        <thead>
          <tr>
            <td>Case Details</td>
            <td>Name of the regulatory/ enforcement agencies/ judicial institutions</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{caseDetail}}</td>
            <td>{{regulatoryName}}</td>
          </tr>
        </tbody>
      </table>

      <div class="table-title mb-0">
        4. Of the instances disclosed in Question 2 above, details of the Appeal/ Revision preferred in cases where
        monetary or non-monetary action has been appealed.
      </div>
      <div class="normal-input-sectionC mt-2">
        {{data.SectionC.principleOne.weblink}}
      </div>

      <div class="table-title">
        5. Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any law enforcement
        agency for the charges of bribery/ corruption:
      </div>

      <table class="table mt-3">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Directors</td>
            <td class="text-end">{{previousYear}}</td>
            <td class="text-end">{{currentYear}}</td>
          </tr>
          <tr>
            <td>KMPs</td>
            <td class="text-end">{{previousYear}}</td>
            <td class="text-end">{{currentYear}}</td>
          </tr>
          <tr>
            <td>Employees</td>
            <td class="text-end">{{previousYear}}</td>
            <td class="text-end">{{currentYear}}</td>
          </tr>
          <tr>
            <td>Workers</td>
            <td class="text-end">{{previousYear}}</td>
            <td class="text-end">{{currentYear}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title">
        6. Details of complaints with regard to conflict of interest:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2"></th>
            <th colspan="2" class="text-center">
              FY {{fyear}} (Current Financial Year)
            </th>
            <th colspan="2" class="text-center">
              FY {{previousYear}} (Previous Financial Year)
            </th>
          </tr>
          <tr>
            <th class="text-end rowspan-border">Number</th>
            <th>Remarks</th>
            <th class="text-end">Number</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Number of complaints received in relation to issues of Conflict of Interest of the Directors</td>
            <td class="text-end">{{currentYear.number}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{previousYear.number}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>
          <tr>
            <td>Number of complaints received in relation to issues of Conflict of Interest of the KMPs</td>
            <td class="text-end">{{currentYear.number}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{previousYear.number}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title">
        7. Provide details of any corrective action taken or underway on issues related to fines / penalties / action
        taken by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and conflicts of
        interest.
      </div>
      <div class="normal-input-sectionC mt-2">
        {{collectiveAction}}
      </div>
      <div class="table-title">
        8. Number of days of accounts payables ((Accounts payable *365) / Cost of goods/services procured) in the
        following format:
      </div>

      <table class="table mt-3">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">
              FY {{fyear}} (Current Financial Year)

            </th>
            <th class="text-end">
              FY {{previousYear}} (Previous Financial Year)
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{payable}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{currentYear}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title">
        9. Open-ness of business - Provide details of concentration of purchases and sales with trading houses, dealers,
        and related parties along-with loans and advances & investments, with related parties, in the following format:
      </div>


      <table class="table mt-3">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Metrics</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center text-black border-right" rowspan="4" style="line-height: 200px;">
              <div>
                Concentration of Purchases
              </div>
            </td>
          </tr>
          <tr>
            <td class="rowspan-td-border">a. Purchases from trading houses as % of total purchases</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">b. Number of trading houses where purchases are made from</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">c. Purchases from top 10 trading houses as % of total purchases from trading
              houses</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td className="text-center text-black border-right" rowspan="4" style="line-height: 200px;">
              <div>
                Concentration of Sales
              </div>
            </td>
          </tr>
          <tr>
            <td class="rowspan-td-border">a. Sales to dealers / distributors as % of total sales</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">b. Number of dealers / distributors to whom sales are made</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">c. Sales to top 10 dealers/ distributors as % of total sales to dealers/
              distributors</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td className="text-center text-black border-right" rowspan="5" style="line-height: 225px;">
              <div>
                Share of RPTs in
              </div>
            </td>
          </tr>
          <tr>
            <td class="rowspan-td-border">a. Purchases (Purchases with related parties/ Total Purchases)</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">b. Sales (Sales to related parties/ Total Sales)</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">c. Loans & advances (Loans & advances given to related parties/ Total loans &
              advances)</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">d. Investments (Investments in related parties/ Total Investments made)</td>
            <td class="text-end">{{innerItem.currentYear}}</td>
            <td class="text-end">{{innerItem.previousYear}}</td>
          </tr>
        </tbody>

      </table>
      <div class="d-flex align-items-center gap-2">
        <div class="principle-button-type">
          Principle 1
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>
      <div class="table-title">
        1. Awareness programmes conducted for value chain partners on any of the Principles during the financial year:
      </div>
      <table class="table mt-3">
        <thead>
          <tr>
            <th>Total number of awareness programmes held</th>
            <th>Topics / principles covered under the training</th>
            <th>
              %age of value chain partners covered (by value
              of business done with such partners) under the
              awareness programmes
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{totalAwarenessPrograms}}</td>
            <td>{{topicsCovered}}</td>
            <td>{{valueChainPartnersCoverd}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mb-0">
        2. Does the entity have processes in place to avoid/ manage conflict of interests involving members of the
        Board? (Yes/No) If Yes, provide details of the same.
      </div>
      <div class="normal-input mt-2">
        {{data.SectionC.principleOne.entityHasProcess}}
      </div>
      <!-- <div class="table-title ms-3 opacity-50 mt-0">
        {{data.SectionC.principleOne.entityHasProcessDetail}}
      </div> -->
    </div>
  </div>


  <div class="page">
    <div class="content-wrapper general-disclosure-page ">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="principle-wrapper">
        <div class="principle-title">
          PRINCIPLE-2:
        </div>
        <div class="principle-description">
          Businesses should provide goods and services in a manner that is sustainable and safe
        </div>
      </div>
      <div class="table-sub-title mt-3">Essential Indicators</div>
      <div class="table-title">1. Percentage of R&D and capital expenditure (capex) investments in specific technologies
        to improve the environmental and social impacts of product and processes to total R&D and capex investments made
        by the entity, respectively.</div>

      <table class="table-sectionC mt-3" style="table-layout: unset;">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
            <th>Details of improvements in environmental and social impacts</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>R&D</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
            <td>{{envImpact}}</td>
          </tr>
          <tr>
            <td>Capex</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
            <td>{{envImpact}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mb-0">
        2. a. Does the entity have procedures in place for sustainable sourcing? (Yes/No)
      </div>

      <div class="normal-input mt-3">
        <div class="ms-3">
          {{data.SectionC.principleTwo.entityHasProcedures}}
        </div>
      </div>
      <div class="mt-3">
        <div class="table-title mt-0">
          b. If yes, what percentage of inputs were sourced sustainably?
        </div>
        <div class="normal-input mt-3">
          {{returnAlternateValue data.SectionC.principleTwo.sustainablePercent "percent"}}
        </div>
      </div>
      <!-- <div class="ms-3">
        <div class="table-title ms-3 mt-0">
          Description
        </div>
        <div class="table-title ms-3 opacity-50 mt-0">
          {{data.SectionC.principleTwo.sustainablePercentDescription}}
        </div>
      </div> -->
      <div class="table-title mb-0">
        3. Describe the processes in place to safely reclaim your products for reusing, recycling and disposing at the
        end of life, for (a) Plastics (including packaging) (b) E-waste (c) Hazardous waste and (d) other waste.
      </div>
      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Product</th>
            <th>Process to safely reclaim the product</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>a. Plastics (including packaging)</td>

            <td>{{data.SectionC.principleTwo.plasticRecycle}}</td>
          </tr>
          <tr>
            <td>b. E-waste</td>

            <td>{{data.SectionC.principleTwo.eWasteRecycle}}</td>
          </tr>
          <tr>
            <td>c. Hazardous Waste</td>
            <td>{{data.SectionC.principleTwo.hazardousWasteRecycle}}</td>
          </tr>
          <tr>
            <td>d. Other Waste</td>
            <td>{{data.SectionC.principleTwo.otherWaste}}</td>
          </tr>

        </tbody>
      </table>
      <!-- <div class="ms-3">
        <div class="table-title mb-0 mt-2">
          a. Plastics (including packaging)
        </div>
        <div class="table-title ms-3 opacity-50 mt-0">
          {{data.SectionC.principleTwo.plasticRecycle}}
        </div>
        <div class="table-title mb-0 mt-2">
          b. E-waste
        </div>
        <div class="table-title ms-3 opacity-50 mt-0">{{data.SectionC.principleTwo.eWasteRecycle}}</div>
        <div class="table-title mb-0 mt-2">
          c. Hazardous Waste
        </div>
        <div class="table-title ms-3 opacity-50 mt-0">{{data.SectionC.principleTwo.hazardousWasteRecycle}}</div>
        <div class="table-title mb-0 mt-2">
          d. Other waste
        </div>
        <div class="table-title ms-3 opacity-50 mt-0">{{data.SectionC.principleTwo.otherWaste}}</div>
      </div> -->
      <div class="table-title mb-0">
        4. Whether Extended Producer Responsibility (EPR) is applicable to the entity’s activities (Yes / No). If yes,
        whether the waste collection plan is in line with the Extended Producer Responsibility (EPR) plan submitted to
        Pollution Control Boards? If not, provide steps taken to address the same.
      </div>
      <div class="normal-input my-2">
        Yes, for Petrochemicals business we are registered on
        Central Pollution Control Board (CPCB) portal as per
        Plastic Waste Management 2016 rules requirement.
        The Company has complied with its EPR obligation.
        EPR is not applicable to the refining/Exploration &
        Production business.
      </div>
      <div class="d-flex align-items-center gap-2 mt-3">
        <div class="principle-button-type">
          Principle 1
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>
      <div class="table-title">
        1. Has the entity conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for
        manufacturing industry) or for its services (for service industry)? If yes, provide details in the following
        format?
      </div>

      <table class="table=sectionC mt-3">
        <thead>
          <tr>
            <th>NIC Code</th>
            <th>Name of Product /Service</th>
            <th class="text-end">% of total Turnover contributed</th>
            <th>Boundary for which the Life Cycle Perspective / Assessment was conducted</th>
            <th>Whether conducted by independent external agency
              (Yes/No)</th>
            <th>Results communicated in public domain
              (Yes/No)

              If yes, provide the web-link.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{nicCode}}</td>
            <td>{{productName}}</td>
            <td class="text-end">{{returnAlternateValue percentageTurnover "percent"}}</td>
            <td>{{assessmentBoundary}}</td>
            <td>{{externalAgency}}</td>
            <td>{{publicResults}}</td>
          </tr>
        </tbody>
      </table>
      <div class="table-title">
        2. If there are any significant social or environmental concerns and/or risks arising from production or
        disposal of your products / services, as identified in the Life Cycle Perspective / Assessments (LCA) or through
        any other means, briefly describe the same along-with action taken to mitigate the same.
      </div>

      <table class="table mt-5">
        <thead>
          <tr>
            <th>Name of Product /Service</th>
            <th>Description of the risk / concern</th>
            <th>Action Taken</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{productName}}</td>
            <td>{{riskDescription}}</td>
            <td>{{actionTaken}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        3. Percentage of recycled or reused input material to total material (by value) used in production (for
        manufacturing industry) or providing services (for service industry).
      </div>

      <table class="table mt-3">
        <thead>
          <tr>
            <th rowspan="2">Indicate input material</th>
            <th colspan="2" class="text-center">Recycled or re-used input material to total material</th>
          </tr>
          <tr>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionC.principleTwo.percentageRecycle}}
          <tr>
            <td>{{inputMaterial}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
      <div class="table-title mt-5">
        4. Of the products and packaging reclaimed at end of life of products, amount (in metric tonnes) reused,
        recycled, and safely disposed, as per the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2"></th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Previous Financial Year)</th>
          </tr>
          <tr class="text-end">
            <th>Re-Used</th>
            <th>Recycled</th>
            <th>Safely Disposed</th>
            <th>Re-Used</th>
            <th>Recycled</th>
            <th>Safely Disposed</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Plastics (including packaging)</td>
            <td class="text-end">{{currentYear.reUsed}}</td>
            <td class="text-end">{{currentYear.reCycled}}</td>
            <td class="text-end">{{currentYear.disposed}}</td>
            <td class="text-end">{{previousYear.reUsed}}</td>
            <td class="text-end">{{previousYear.reCycled}}</td>
            <td class="text-end">{{previousYear.disposed}}</td>
          </tr>
          <tr>
            <td>E-waste</td>
            <td class="text-end">{{currentYear.reUsed}}</td>
            <td class="text-end">{{currentYear.reCycled}}</td>
            <td class="text-end">{{currentYear.disposed}}</td>
            <td class="text-end">{{previousYear.reUsed}}</td>
            <td class="text-end">{{previousYear.reCycled}}</td>
            <td class="text-end">{{previousYear.disposed}}</td>
          </tr>

          <tr>
            <td>Hazardous waste</td>
            <td class="text-end">{{currentYear.reUsed}}</td>
            <td class="text-end">{{currentYear.reCycled}}</td>
            <td class="text-end">{{currentYear.disposed}}</td>
            <td class="text-end">{{previousYear.reUsed}}</td>
            <td class="text-end">{{previousYear.reCycled}}</td>
            <td class="text-end">{{previousYear.disposed}}</td>
          </tr>
          <tr>
            <td>Other waste</td>
            <td class="text-end">{{currentYear.reUsed}}</td>
            <td class="text-end">{{currentYear.reCycled}}</td>
            <td class="text-end">{{currentYear.disposed}}</td>
            <td class="text-end">{{previousYear.reUsed}}</td>
            <td class="text-end">{{previousYear.reCycled}}</td>
            <td class="text-end">{{previousYear.disposed}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        5. Reclaimed products and their packaging materials (as percentage of products sold) for each product category.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Indicate the category</th>
            <th class="text-end">Reclaimed products and their packaging materials as % of total products sold in
              respective category</th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionC.principleTwo.reclaimedProducts}}
          <tr>
            <td>{{category}}</td>
            <td class="text-end">{{returnAlternateValue productPercent "percent"}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
    </div>
  </div>


  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="principle-wrapper mt-2">
        <div class="principle-title">
          PRINCIPLE-3:
        </div>
        <div class="principle-description">
          Businesses should respect and promote
          the wellbeing of all employees,
          including those in their value chains
        </div>
      </div>
      <div class="table-sub-title mt-3">Essential Indicators</div>
      <div class="table-title">
        1. a. Details of measures for the well-being of employees:
      </div>

      <table class="table mt-3">
        <thead>
          <tr>
            <th rowspan="3">Category</th>
            <th colspan="11" class="text-center">% of employees covered by</th>
          </tr>
          <tr class="text-end">
            <th rowspan="2" class="rowspan-border">
              <div>Total</div>
              <div>(A)</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Health</div>
              <div>insurance</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Accident</div>
              <div>insurance</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Maternity</div>
              <div>benefits</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Paternity</div>
              <div>Benefits</div>
            </th>
            <th class="text-center">
              <div>Day Care</div>
              <div>facilities</div>
            </th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">No. (B)</th>
            <th>%(B/A)</th>
            <th>No. (C)</th>
            <th>%(C/A)</th>
            <th>No. (D)</th>
            <th>%(D/A)</th>
            <th>No. (E)</th>
            <th>%(E/A)</th>
            <th>No. (F)</th>
            <th>%(F/A)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="12" class="text-start text-black fw-semibold">Permanent Employees</td>
          </tr>

          <tr class="fw-semibold opacity-100">
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>


          <tr class="fw-semibold opacity-100">
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>
          <tr class="fw-semibold opacity-100">
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>



          <tr>
            <td colspan="12" class="text-start text-black fw-semibold ">Other than Permanent employees</td>
          </tr>

          <tr class="fw-semibold opacity-100">
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>


          <tr class="fw-semibold opacity-100">
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>
          <tr class="fw-semibold opacity-100">
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        1. b. Details of measures for the wellbeing of workers:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="3">Category</th>
            <th colspan="11" class="text-center">% of workers covered by</th>
          </tr>
          <tr class="text-end">
            <th rowspan="2" class="rowspan-border">
              <div>Total</div>
              <div>(A)</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Health</div>
              <div>insurance</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Accident</div>
              <div>insurance</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Maternity</div>
              <div>benefits</div>
            </th>
            <th colspan="2" class="text-center">
              <div>Paternity</div>
              <div>Benefits</div>
            </th>
            <th class="text-center">
              <div>Day Care</div>
              <div>facilities</div>
            </th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">No. (B)</th>
            <th>%(B/A)</th>
            <th>No. (C)</th>
            <th>%(C/A)</th>
            <th>No. (D)</th>
            <th>%(D/A)</th>
            <th>No. (E)</th>
            <th>%(E/A)</th>
            <th>No. (F)</th>
            <th>%(F/A)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="12" class="text-start text-black fw-semibold">Permanent workers</td>
          </tr>

          <tr class="fw-semibold opacity-100">
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>


          <tr class="fw-semibold opacity-100">
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>
          <tr class="fw-semibold opacity-100">
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>

          <tr>
            <td colspan="12" class="text-start text-black fw-semibold">Other than Permanent Workers</td>
          </tr>

          <tr class="fw-semibold opacity-100">
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>


          <tr class="fw-semibold opacity-100">
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>
          <tr class="fw-semibold opacity-100">
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue total "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue healthInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurance "number"}}</td>
            <td class="text-end">{{returnAlternateValue accidentInsurancePercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue maternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefits "number"}}</td>
            <td class="text-end">{{returnAlternateValue paternityBenefitsPercent "percent"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacility "number"}}</td>
            <td class="text-end">{{returnAlternateValue dayCareFacilityPercent "percent"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        1. c. Spending on measures towards well-being of employees and workers (including permanent and other than
        permanent) in the following format.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{costIncurred}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        2. Details of retirement benefits, for Current FY and Previous Financial Year.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>

            <th rowspan="2">Benefits</th>
            <th colspan="3" class="text-center">FY {{fyear}}(Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}}(Previous Financial Year)</th>
          </tr>
          <tr>
            <th class="text-end rowspan-border">No. of employees covered as a % of total employees</th>
            <th class="text-end">No. of workers covered as a % of total worker</th>
            <th>Deducted and deposited with the authority (Y/N/N.A.)</th>
            <th class="text-end">No. of employees covered as a % of total employees</th>
            <th class="text-end">No. of workers covered as a % of total worker</th>
            <th>Deducted and deposited with the authority (Y/N/N.A.)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>PF</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.workersCovered "percent"}}</td>
            <td>{{item.currentYear.deductedDeposited}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.workersCovered "percent"}}</td>
            <td>{{item.previousYear.deductedDeposited}}</td>
          </tr>

          <tr>
            <td>Gratuity</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.workersCovered "percent"}}</td>
            <td>{{item.currentYear.deductedDeposited}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.workersCovered "percent"}}</td>
            <td>{{item.previousYear.deductedDeposited}}</td>
          </tr>
          <tr>
            <td>ESI</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.workersCovered "percent"}}</td>
            <td>{{item.currentYear.deductedDeposited}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.workersCovered "percent"}}</td>
            <td>{{item.previousYear.deductedDeposited}}</td>
          </tr>
          <tr>
            <td>Others – please specify</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.currentYear.workersCovered "percent"}}</td>
            <td>{{item.currentYear.deductedDeposited}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.employeesCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.previousYear.workersCovered "percent"}}</td>
            <td>{{item.previousYear.deductedDeposited}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mb-0">
        3. Accessibility of workplaces: Are the premises/ offices of the entity accessible to differently abled
        employees and workers, as per the requirements of the Rights of Persons with Disabilities Act, 2016? If not,
        whether any steps are being taken by the entity in this regard.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleThree.accessibleToEmployees}}</div>
      <div class="table-title mb-0">
        4. Does the entity have an equal opportunity policy as per the Rights of Persons with Disabilities Act, 2016? If
        so, provide a web-link to the policy.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleThree.equalOpportunityPolicy}}</div>
      <div class="table-title mb-0 mt-5">
        5. Return to work and Retention rates of permanent employees and workers that took parental leave.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2">Benefits</th>
            <th colspan="2" class="text-center">Permanent employees</th>
            <th colspan="2" class="text-center">Permanent workers</th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">Return to work rate</th>
            <th>Retention rate</th>
            <th>Return to work rate</th>
            <th>Retention rate</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue permanentEmployee.returnToWork "number"}}</td>
            <td class="text-end">{{returnAlternateValue permanentEmployee.retention "percent"}}</td>
            <td class="text-end">{{returnAlternateValue nonPermanentEmployee.returnToWork "number"}}</td>
            <td class="text-end">{{returnAlternateValue nonPermanentEmployee.retention "percent"}}</td>
          </tr>
          <tr>
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue permanentEmployee.returnToWork "number"}}</td>
            <td class="text-end">{{returnAlternateValue permanentEmployee.retention "percent"}}</td>
            <td class="text-end">{{returnAlternateValue nonPermanentEmployee.returnToWork "number"}}</td>
            <td class="text-end">{{returnAlternateValue nonPermanentEmployee.retention "percent"}}</td>
          </tr>

          <tr>
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue permanentEmployee.returnToWork "number"}}</td>
            <td class="text-end">{{returnAlternateValue permanentEmployee.retention "percent"}}</td>
            <td class="text-end">{{returnAlternateValue nonPermanentEmployee.returnToWork "number"}}</td>
            <td class="text-end">{{returnAlternateValue nonPermanentEmployee.retention "percent"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        6. Is there a mechanism available to receive and redress grievances for the following categories of employees
        and worker? If yes, give details of the mechanism in brief.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th></th>
            <th>Yes/No</th>
            <th>(If Yes, then give details of the mechanism in brief)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Permanent Workers</td>
            <td>{{grievanceMechanismExists}}</td>
            <td>{{grievanceMechanismDetail}}</td>
          </tr>
          <tr>
            <td>Other than Permanent Workers</td>
            <td>{{grievanceMechanismExists}}</td>
            <td>{{grievanceMechanismDetail}}</td>
          </tr>
          <tr>
            <td>Permanent Employees</td>
            <td>{{grievanceMechanismExists}}</td>
            <td>{{grievanceMechanismDetail}}</td>
          </tr>
          <tr>
            <td>Other than Permanent Employees</td>
            <td>{{grievanceMechanismExists}}</td>
            <td>{{grievanceMechanismDetail}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        7. Membership of employees and worker in association(s) or Unions recognised by the listed entity:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Previous Financial Year)</th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">Total employees/workers in respective category (A)</th>
            <th>No. of employees/workers in respective category, who are part of association(s) or Union (B)</th>
            <th>% (B/A)</th>
            <th>Total employees/workers in respective category (C)</th>
            <th>No. of employees/workers in respective category, who are part of association(s) or Union (D)</th>
            <th>% (C/D)</th>
          </tr>
        </thead>
        <tbody>


          <tr>
            <td>Total Permanent Employees</td>
            <td class="text-end">{{returnAlternateValue currentYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue currentYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue currentYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Total Permanent Workers</td>
            <td class="text-end">{{returnAlternateValue currentYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue currentYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue currentYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.totalEmployees "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.employeeOfAssociation "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>






        </tbody>
      </table>
      <div class="table-title">
        8. Details of training given to employees and workers:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Current Financial Year)</th>
          </tr>
          <tr>
            <th class="rowspan-border">Total employees/workers in respective category (A)</th>
            <th>No. of employees/workers in respective category, who received training on Health and Safety (B)</th>
            <th>% (B/A)</th>
            <th>Total employees/workers in respective category (C)</th>
            <th>No. of employees/workers in respective category, who received training on Health and Safety (D)</th>
            <th>% (D/C)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7" class="text-start text-black fw-bold">Employees</td>
          </tr>

          <tr>
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{currentYear.percentage.healthAndSafety}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{previousYear.percentage.healthAndSafety}}%</td>
          </tr>
          <tr>
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{currentYear.percentage.healthAndSafety}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{previousYear.percentage.healthAndSafety}}%</td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{currentYear.percentage.healthAndSafety}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{previousYear.percentage.healthAndSafety}}%</td>
          </tr>

          <tr>
            <td colspan="7" class="text-start text-black fw-bold">Workers</td>
          </tr>
          <tr>
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{currentYear.percentage.healthAndSafety}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{previousYear.percentage.healthAndSafety}}%</td>
          </tr>
          <tr>
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{currentYear.percentage.healthAndSafety}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{previousYear.percentage.healthAndSafety}}%</td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{currentYear.percentage.healthAndSafety}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.healthAndSafety "number"}}</td>
            <td class="text-end">{{previousYear.percentage.healthAndSafety}}%</td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="table-title mt-0">
        On Skill Upgradation
      </div> -->
      <!-- <hr>
      <table class="table mt-3">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Current Financial Year)</th>
          </tr>
          <tr>
            <th>Total employees/workers in respective category (A)</th>
            <th>No. of employees/workers in respective category, who received Skill Training (B)</th>
            <th>% (B/A)</th>
            <th>Total employees/workers in respective category (C)</th>
            <th>No. of employees/workers in respective category, who received Skill Training (D)</th>
            <th>% (D/C)</th>
          </tr>
        </thead>
        <tbody>
          <tr style="background-color: #F2F3F7;">
            <td colspan="7" class="text-center fw-bold">Permanent Employees</td>
          </tr>
          {{#each data.SectionC.principleThree.trainingGivenToEmployee}}
          <tr>
            <td>{{category}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.skillUpgradation "number"}}</td>
            <td class="text-end">{{currentYear.percentage.skillUpgradation}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.skillUpgradation "number"}}</td>
            <td class="text-end">{{previousYear.percentage.skillUpgradation}}%</td>
          </tr>
          {{/each}}
          <tr style="background-color: #F2F3F7;">
            <td colspan="7" class="text-center fw-bold">Permanent Workers</td>
          </tr>
          {{#each data.SectionC.principleThree.trainingGivenToWorker}}
          <tr>
            <td>{{category}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.skillUpgradation "number"}}</td>
            <td class="text-end">{{currentYear.percentage.skillUpgradation}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.skillUpgradation "number"}}</td>
            <td class="text-end">{{previousYear.percentage.skillUpgradation}}%</td>
          </tr>
          {{/each}}
        </tbody>
      </table> -->


      <div class="table-title mt-5">
        9. Details of performance and career development reviews of employees and worker:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Current Financial Year)</th>
          </tr>
          <tr>
            <th class="text-end rowspan-border">Total (A)</th>
            <th class="text-end">No.(B)</th>
            <th class="text-end">% (B/A)</th>
            <th class="text-end">Total (C)</th>
            <th class="text-end">No.(D)</th>
            <th class="text-end">% (D/C)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7" class="text-start text-black fw-bold">Employees</td>
          </tr>

          <tr>
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.number "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.number "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>

          <tr>
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.number "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.number "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.number "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.number "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>

          <tr>
            <td colspan="7" class="text-start text-black fw-bold">Workers</td>
          </tr>

          <tr>
            <td>Male</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.number "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.number "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>

          <tr>
            <td>Female</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.number "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.number "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.number "number"}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.number "number"}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title mb-0">
        10. Health and safety management system:
      </div>
      <div class="ms-3">
        <div class="normal-input mb-0">
          a. Whether an occupational health and safety management system has been implemented by the entity? (Yes/ No).
          If yes, the coverage such system?
        </div>
        <div class="gray-border-input mt-0">
          {{data.SectionC.principleThree.healthAndSafetyImplemented}}
        </div>

        <div class="normal-input mb-0 mt-3">
          b. What are the processes used to identify work-related hazards and assess risks on a routine and non-routine
          basis by the entity?
        </div>
        <div class="gray-border-input mt-0">
          {{data.SectionC.principleThree.hazardIdentifyProcess}}
        </div>
        <div class="normal-input mb-0 mt-3">
          c. Whether you have processes for workers to report the work-related hazards and to remove themselves from
          such risks. (Yes/No)
        </div>
        <div class="gray-border-input mt-0">
          {{data.SectionC.principleThree.reportHazard}}

        </div>
        <div class="normal-input mb-0 mt-3">
          d. Do the employees/ worker of the entity have access to non-occupational medical and healthcare services?
          (Yes/ No)
        </div>
        <div class="gray-border-input mt-0">
          {{data.SectionC.principleThree.healthAndSafetyImplemented}}
        </div>
      </div>

      <div class="table-title mt-5">
        11. Details of safety related incidents, in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Safety Incident/Number</th>
            <th>Category*</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="3">Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked) </td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Employees</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Workers</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>

          <tr>
            <td rowspan="3">Total recordable work-related injuries</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Employees</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Workers</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>
          <tr>
            <td rowspan="3">No. of fatalities</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Employees</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Workers</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>

          <tr>
            <td rowspan="3">High consequence work-related injury or ill-health (excluding fatalities)</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Employees</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>
          <tr>
            <td class="rowspan-td-border">Workers</td>
            <td class="text-end">{{innerItem.regulatoryName}}</td>
            <td class="text-end">{{innerItem.briefOfCase}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mb-0">
        12. Describe the measures taken by the entity to ensure a safe and healthy work place.
      </div>
      <div class="normal-input my-2"><span
          style="white-space: pre-line">{{data.SectionC.principleThree.measureToEnsureSafety}}</span></div>
      <div class="table-title">
        13. Number of Complaints on the following made by employees and workers:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2"></th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Current Financial Year)</th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">Filed during the year</th>
            <th>Pending resolution at the end of year</th>
            <th class="text-start">Remarks</th>
            <th>Filed during the year</th>
            <th>Pending resolution at the end of year</th>
            <th class="text-start">Remarks</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Working Conditions</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.resolutionPending "number"}}</td>
            <td class="text-start">{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.resolutionPending "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>
          <tr>
            <td>Health & Safety</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.resolutionPending "number"}}</td>
            <td class="text-start">{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.resolutionPending "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title">
        14. Assessments for the year:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">% of your plants and offices that were assessed (by entity or statutory authorities
              orthird parties)
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Health and safety practices</td>
            <td class="text-end">{{returnAlternateValue assessedPlants "percent"}}</td>
          </tr>
          <tr>
            <td>Working Conditions</td>
            <td class="text-end">{{returnAlternateValue assessedPlants "percent"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mb-0">
        15. Provide details of any corrective action taken or underway to address safety-related incidents (if any) and
        on significant risks / concerns arising from assessments of health & safety practices and working conditions.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleThree.correctiveActionsTaken}}</div>

      <div class="d-flex align-items-center gap-2 mt-3">
        <div class="principle-button-type">
          Principle 2
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>

      <div class="table-title mt-2 mb-0">
        1. Does the entity extend any life insurance or any compensatory package in the event of death of (A) Employees
        (Y/N) (B) Workers (Y/N).
      </div>
      <div class="ms-3">
        <div class="table-title">(a) Employees (Y/N)</div>
        <div class="normal-input my-2">{{data.SectionC.principleThree.lifeEnsuranceEmployee}}</div>
        <div class="table-title">(b) Workers (Y/N)</div>
        <div class="normal-input my-2">{{data.SectionC.principleThree.lifeEnsuranceWorker}}</div>
      </div>
      <div class="table-title mt-2 mb-0">
        2. Provide the measures undertaken by the entity to ensure that statutory dues have been deducted and deposited
        by the value chain partners.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleThree.measureTakenForStatutoryDues}}</div>
      <div class="table-title">
        3. Details of complaints with regard to conflict of interest:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2"></th>
            <th colspan="2" class="text-center">Total no. of affected employees/ workers</th>
            <th colspan="2" class="text-center">
              No. of employees/workers that are rehabilitated and placed in suitable employment or whose family members
              have been placed in suitable employment
            </th>
          </tr>
          <tr>
            <th class="text-end rowspan-border">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Employees</td>
            <td class="text-end">{{affectedEmployees.currentYear}}</td>
            <td class="text-end">{{affectedEmployees.previousYear}}</td>
            <td class="text-end">{{rehabilitatedEmployee.currentYear}}</td>
            <td class="text-end">{{rehabilitatedEmployee.previousYear}}</td>
          </tr>

          <tr>
            <td>Workers</td>
            <td class="text-end">{{affectedWorkers.currentYear}}</td>
            <td class="text-end">{{affectedWorkers.previousYear}}</td>
            <td class="text-end">{{rehabilitatedWorkers.currentYear}}</td>
            <td class="text-end">{{rehabilitatedWorkers.previousYear}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mb-0">
        4. Does the entity provide transition assistance programs to facilitate continued employability and the
        management of career endings resulting from retirement or termination of employment? (Yes/ No)
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleThree.transAssitanceProgram}}</div>
      <div class="table-title">
        5. Details on assessment of value chain partners:
      </div>

      <table class="table-sectionC">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">% of value chain partners (by value of business done with such partners) that were
              assessed</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Health and safety practices</td>
            <td class="text-end">{{returnAlternateValue assessedPartners "percent"}}</td>
          </tr>
          <tr>
            <td>Working Conditions</td>
            <td class="text-end">{{returnAlternateValue assessedPartners "percent"}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title mb-0">
        6. Provide details of any corrective actions taken or underway to address significant risks / concerns arising
        from assessments of health and safety practices and working conditions of value chain partners.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleThree.correctiveActionsTaken}}</div>
    </div>
  </div>

  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="principle-wrapper mt-2">
        <div class="principle-title">
          PRINCIPLE-4:
        </div>
        <div class="principle-description">
          Businesses should respect the interests of and be responsive to all its stakeholders
        </div>
      </div>

      <div class="table-sub-title mt-3">Essential Indicators</div>
      <div class="table-title mb-0">
        1. Describe the processes for identifying key stakeholder groups of the entity.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFour.processOfIdentification}}</div>
      <div class="table-title mb-0">
        2. List stakeholder groups identified as key for your entity and the frequency of engagement with each
        stakeholder group.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Stakeholder Group</th>
            <th>
              Whether identified as Vulnerable & Marginalized Group (Yes/No)
            </th>
            <th>
              Channels of communication
              (Email, SMS, Newspaper, Pamphlets, Advertisement, Community Meetings, Notice Board, Website), Other
            </th>
            <th>
              Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)
            </th>
            <th>
              Purpose and scope of engagement including key topics and concerns raised during such engagement
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Employees</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>
          <tr>
            <td>Shareholders/ Investors</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>
          <tr>
            <td>Customers</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>
          <tr>
            <td>Suppliers</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>
          <tr>
            <td>Regulators</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>
          <tr>
            <td>Community and NGOs</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>

          <tr>
            <td>Media</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>

          <tr>
            <td>Peers and Key Partners</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>
          <tr>
            <td>Academics</td>
            <td>{{vulnerable}}</td>
            <td>{{channelsOfCommunication}}</td>
            <td>{{frequencyOfEngangement}}</td>
            <td>{{purposeOfEngangement}}</td>
          </tr>

        </tbody>
      </table>

      <div class="d-flex align-items-center gap-2 mt-3">
        <div class="principle-button-type">
          Principle 4
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>
      <div class="table-title mb-0">
        1. Provide the processes for consultation between stakeholders and the Board on economic, environmental, and
        social topics or if consultation is delegated, how is feedback from such consultations provided to the Board.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFour.processOfConsultation}}</div>
      <div class="table-title mb-0 mt-4">
        2. Whether stakeholder consultation is used to support the identification and management of environmental, and
        social topics (Yes / No).
      </div>
      <div class="table-title mb-0 mt-2">

        If so, provide details of instances as to how the inputs received from stakeholders on these topics were
        incorporated into policies and activities of the entity.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFour.consultationForSupport}}</div>
      <div class="table-title mb-0 mt-4">
        3. Provide details of instances of engagement with, and actions taken to, address the concerns of vulnerable/
        marginalized stakeholder groups.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFour.instancesOfEngagement}}</div>
    </div>
  </div>

  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="principle-wrapper mt-2">
        <div class="principle-title">
          PRINCIPLE-5:
        </div>
        <div class="principle-description">
          Businesses should respect and
          promote human rights
        </div>
      </div>

      <div class="table-sub-title mt-3">Essential Indicators</div>
      <div class="table-title mb-0 mt-3">
        1. Employees and workers who have been provided training on human rights issues and policy(ies) of the entity,
        in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Previous Financial Year)</th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">Total (A)</th>
            <th>No. of employees/workers covered (B)</th>
            <th>% (B/A)</th>
            <th>Total (C)</th>
            <th>No. of employees/workers covered (D)</th>
            <th>% (D/C)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7" class="text-start text-black fw-bold">Employees</td>
          </tr>

          <tr>
            <td>Permanent</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{currentYear.noOfEmployees}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{previousYear.noOfEmployees}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>

          <tr>
            <td>Other than permanent</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{currentYear.noOfEmployees}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{previousYear.noOfEmployees}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Total Employees</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{currentYear.noOfEmployees}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{previousYear.noOfEmployees}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>

          <tr>
            <td colspan="7" class="text-start text-black fw-bold">Workers</td>
          </tr>

          <tr>
            <td>Permanent</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{currentYear.noOfEmployees}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{previousYear.noOfEmployees}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>

          <tr>
            <td>Other than permanent</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{currentYear.noOfEmployees}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{previousYear.noOfEmployees}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
          <tr>
            <td>Total Employees</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{currentYear.noOfEmployees}}</td>
            <td class="text-end">{{currentYear.percentage}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{previousYear.noOfEmployees}}</td>
            <td class="text-end">{{previousYear.percentage}}%</td>
          </tr>
        </tbody>
      </table>

      <div class="table-title mb-0">
        2. Details of minimum wages paid to employees and workers, in the following format:
      </div>
      <hr>
      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="3">Category</th>
            <th colspan="5" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="5" class="text-center">FY {{previousYear}} (PreviousFinancial Year)</th>
          </tr>
          <tr class="text-end">
            <th rowspan="2" class="rowspan-border">Total (A)</th>
            <th colspan="2" class="text-center">Equal to Minimum Wage</th>
            <th colspan="2" class="text-center">More than Minimum Wage</th>
            <th rowspan="2">Total (D)</th>
            <th colspan="2" class="text-center">Equal to Minimum Wage</th>
            <th colspan="2" class="text-center">More than Minimum Wage</th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">No. (B)</th>
            <th>% (B/A)</th>
            <th>No. (C)</th>
            <th>% (C/A)</th>
            <th>No. (E)</th>
            <th>% (E/D)</th>
            <th>No. (F)</th>
            <th>% (F/D)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="11" class="text-center  text-black fw-bold">Employees</td>
          </tr>
          <tr>
            <td colspan="11" class="text-start  text-black fw-bold">Permanent</td>
          </tr>

          <tr>
            <td>Male</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>


          <tr>
            <td>Female</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>

          <tr>
            <td colspan="11" class="text-start fw-bold">Other than Permanent</td>
          </tr>
          <tr>
            <td>Male</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>


          <tr>
            <td>Female</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>

          <tr>
            <td colspan="11" class="text-center text-black  fw-bold">Workers</td>
          </tr>
          <tr>
            <td colspan="11" class="text-start  text-black ">Permanent</td>
          </tr>
          <tr>
            <td>Male</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>


          <tr>
            <td>Female</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>
          <tr>
            <td colspan="11" class="text-start fw-bold">Other than Permanent</td>
          </tr>
          <tr>
            <td>Male</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>


          <tr>
            <td>Female</td>
            <td class="text-end">{{currentYear.total}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue currentYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{currentYear.percentage.moreThanMinimum}}%</td>
            <td class="text-end">{{previousYear.total}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.equalToMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.equalToMinimum}}%</td>
            <td class="text-end">{{returnAlternateValue previousYear.moreThanMinimum "number"}}</td>
            <td class="text-end">{{previousYear.percentage.moreThanMinimum}}%</td>
          </tr>
        </tbody>
      </table>

      <div class="table-title mb-0 mt-5">
        3. Details of remuneration/salary/wages
      </div>
      <div class="table-sub-title my-2">
        a. Median remuneration/ wages:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th colspan="2" class="text-center">Male</th>
            <th colspan="2" class="text-center">Female</th>
          </tr>
          <tr class="text-end">
            <th class="rowspan-border">Number</th>
            <th>Median remuneration/salary/wages of respective category</th>
            <th>Number</th>
            <th>Median remuneration/salary/wages of respective category</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Board of Directors (BoD)</td>
            <td class="text-end">{{male.number}}</td>
            <td class="text-end">{{male.salary}}</td>
            <td class="text-end">{{female.number}}</td>
            <td class="text-end">{{female.salary}}</td>
          </tr>
          <tr>
            <td>Key Managerial Personnel</td>
            <td class="text-end">{{male.number}}</td>
            <td class="text-end">{{male.salary}}</td>
            <td class="text-end">{{female.number}}</td>
            <td class="text-end">{{female.salary}}</td>
          </tr>
          <tr>
            <td>Employees other than BoD and KMP</td>
            <td class="text-end">{{male.number}}</td>
            <td class="text-end">{{male.salary}}</td>
            <td class="text-end">{{female.number}}</td>
            <td class="text-end">{{female.salary}}</td>
          </tr>
          <tr>
            <td>Workers</td>
            <td class="text-end">{{male.number}}</td>
            <td class="text-end">{{male.salary}}</td>
            <td class="text-end">{{female.number}}</td>
            <td class="text-end">{{female.salary}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title mb-0 mt-5">
        3. Details of remuneration/salary/wages
      </div>
      <div class="table-sub-title my-2">
        b. Gross wages paid to females as % of total wages paid by the entity, in the following format:
      </div>

      <table class="table-sectionC">
        <thead>
          <tr>
            <th>Category</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Gross wages paid to females as % of total wages</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
          </tr>
        </tbody>
      </table>
      <div class="table-title mb-0">
        4. Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts or issues
        caused or contributed to by the business? (Yes/No).
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFive.processOfConsultation}}</div>
      <div class="table-title mb-0">
        5. Describe the internal mechanisms in place to redress grievances related to human rights issues.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFive.internalMechanismToRefressGrievance}}
      </div>
      <div class="table-title">
        6. Number of Complaints on the following made by employees and workers:
      </div>

      <table class="table mt-3">
        <thead>
          <tr>
            <th rowspan="2">Category</th>
            <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
            <th colspan="3" class="text-center">FY {{previousYear}} (Previous Financial Year)</th>
          </tr>
          <tr>
            <th class="text-end rowspan-border">Filed during the year</th>
            <th class="text-end">Pending resolution at the end of year</th>
            <th>Remarks</th>
            <th class="text-end">Filed during the year</th>
            <th class="text-end">Pending resolution at the end of year</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Sexual Harassment</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>


          <tr>
            <td>Discrimination at workplace</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>

          <tr>
            <td>Child Labour</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>

          <tr>
            <td>Forced Labour/Involuntary Labour</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>

          <tr>
            <td>Wages</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>

          <tr>
            <td>Other human rights related issues</td>
            <td class="text-end">{{returnAlternateValue currentYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
            <td>{{currentYear.remarks}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.filedDuringYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
            <td>{{previousYear.remarks}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title">
        7. Complaints filed under the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal)
        Act, 2013, in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr class="text-end">
            <th></th>
            <th>FY {{fyear}} (Current Financial Year)</th>
            <th>FY {{previousYear}} (Previous Financial Year)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Total Complaints reported under Sexual Harassment on of Women at Workplace (Prevention, Prohibition and
              Redressal) Act, 2013 (POSH)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Complaints on POSH as a % of female employees / workers</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Complaints on POSH upheld</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
        </tbody>
      </table>

      <div class="table-title mb-0">
        8. Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment cases.
      </div>
      <div class="normal-input my-2">
        {{data.SectionC.principleFive.preventAdverseConsequencesToComplaints}}</div>
      <div class="table-title mb-0">
        9. Do human rights requirements form part of your business agreements and contracts? (Yes/No)
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFive.humanRightsPartOfContract}}</div>
      <div class="table-title">
        10. Assessments for the year:
      </div>

      <table class="table-sectionC">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">
              % of your plants and offices that were assessed (by entity or statutory authorities or third parties)
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Child labour</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Forced/involuntary labour</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Sexual harassment</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Discrimination at workplace</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Wages</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Others – please specify</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
        </tbody>
      </table>

      <div class="table-title mb-0">
        11. Provide details of any corrective actions taken or underway to address significant risks / concerns arising
        from the assessments at Question 10 above.
      </div>
      <div class="normal-input my-2">
        {{data.SectionC.principleFive.correctiveActionSignificantRiskForTen}}
      </div>

      <div class="d-flex align-items-center gap-2 mt-3">
        <div class="principle-button-type">
          Principle 1
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>

      <div class="table-title mb-0">
        1. Details of a business process being modified/ introduced as a result of addressing human rights
        grievances/complaints.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFive.detailsOfBusinessProcessModification}}
      </div>
      <div class="table-title mb-0">
        2. Details of the scope and coverage
        of any Human rights due-diligence
        conducted
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFive.detailsOfScopeByHumanRights}}</div>
      <div class="table-title mb-0">
        3. Is the premise/office of the entity accessible to differently abled visitors, as per the requirements of the
        Rights of Persons with Disabilities Act, 2016?
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleFive.accessibleToDifferntlyAbled}}</div>
      <div class="table-title mb-0">
        4. Details on assessment of value chain partners
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <th></th>
          <th class="text-end">
            % of value chain partners (by value of business done with such partners) that were assessed
          </th>
        </thead>
        <tbody>

          <tr>
            <td>Child labour</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Forced/involuntary labour</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Sexual harassment</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Discrimination at workplace</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Wages</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>
          <tr>
            <td>Others – please specify</td>
            <td class="text-end">{{returnAlternateValue percentageAssessed "percent"}}</td>
          </tr>

        </tbody>
      </table>

      <div class="table-title mb-0">
        5. Provide details of any corrective actions taken or underway to address significant risks/ concerns arising
        from the assessments at Question 4 above.
      </div>
      <div class="normal-input my-2">
        {{data.SectionC.principleFive.correctiveActionSignificantRiskForFour}}</div>
    </div>
  </div>

  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>

      <div class="principle-wrapper mt-2">
        <div class="principle-title">
          PRINCIPLE-6:
        </div>
        <div class="principle-description">
          Businesses should respect and make efforts
          to protect and restore the environment
        </div>
      </div>

      <div class="table-sub-title mt-3">Essential Indicators</div>

      <div class="table-title">
        1. Details of total energy consumption (in Joules or multiples) and energy intensity, in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="3">Parameter</th>
            <th>Unit</th>

            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" class="text-start fw-semibold">From renewable sources</td>
          </tr>

          <tr>
            <td>Total electricity consumption (A)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Total fuel consumption (B)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>

            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Energy consumption through other sources (C)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total energy consumed from renewable sources (A+B+C)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td colspan="3" class="text-start fw-semibold">From non-renewable sources</td>
          </tr>
          <tr>
            <td>Total electricity consumption (D)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Total fuel consumption (E)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Energy consumption through other sources (F)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total energy consumed from nonrenewable sources (D+E+F)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total energy consumed (A+B+C+D+E+F)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Energy intensity per rupee of turnover
              (Total energy consumed / Revenue from operations)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Energy intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP)
              (Total energy consumed / Revenue from operations adjusted for PPP)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Energy intensity in terms of physical output</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Energy intensity (optional) – the relevant metric may be selected by the entity</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Energy intensity (optional) – the relevant metric may be selected by the entity</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
        </tbody>
      </table>
      <div class="table-sub-title fw-semibold mt-3">
        Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2 ">{{data.SectionC.principleSix.totalEnergyConsumptionRenewableNote}}
      </div>
      <div class="table-title mt-5">
        2. Does the entity have any sites / facilities identified as designated consumers (DCs) under the Performance,
        Achieve and Trade (PAT) Scheme of the Government of India? (Y/N) If yes, disclose whether targets set under the
        PAT scheme have been achieved. In case targets have not been achieved, provide the remedial action taken, if
        any.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.designatedConsumerSites}}</div>
      <div class="table-title mt-5">
        3. Provide details of the following disclosures related to water, in the following format:
      </div>
      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="3">Parameter</th>
            <th>Unit</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" class="text-start text-black fw-semibold">Water withdrawal by source (in kilolitres)</td>
          </tr>

          <tr>
            <td>(i) Surface water</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(ii) Groundwater</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(iii) Third party water</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(iv) Seawater/ desalinated water</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(v) Others</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total volume of water withdrawal (in kilolitres) (i + ii + iii + iv + v)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total volume of water consumption (in kilolitres)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Water intensity per rupee of turnover
              (Total water consumption / Revenue from operations)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Water intensity per rupee of turnover adjusted for Purchasing Power Parity
              (PPP) (Total water consumption / Revenue from operations adjusted for PPP)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Water intensity in terms of physical output</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Water intensity
              (optional) – the relevant metric may be selected by the entity</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>


        </tbody>
      </table>
      <div class="table-sub-title mt-3">
        Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.disclosureWaterNote}}</div>
      <div class="table-title mt-5">
        4. Provide the following details related to water discharged
      </div>
      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th rowspan="3">Parameter</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" class="text-start fw-semibold">
              Water discharge by destination and level of treatment (in kilolitres)
            </td>
          </tr>


          <tr>
            <td class="fw-semibold">(i) Surface water</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(ii) To Groundwater</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(iii) To Seawater</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(iv) Sent to third-parties</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(v) Others</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total water discharged (in kilolitres)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
        </tbody>
      </table>
      <div class="table-sub-title mt-3">
        Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.waterDischargeNote}}</div>
      <div class="table-title mt-5">
        5. Has the entity implemented a mechanism for Zero Liquid Discharge? If yes, provide details of its coverage and
        implementation.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.zeroLiquidDischarge}}</div>
      <div class="table-title mt-5">
        6. Please provide details of air emissions (other than GHG emissions) by the entity, in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Please specify unit</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>NOx</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>SOx</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Particulate matter (PM)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Persistent organic pollutants (POP)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Volatile organic compounds (VOC)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Hazardous air pollutants (HAP)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td>Others (Mercury – Hg)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>



        </tbody>
      </table>
      <div class="table-sub-title mt-3">
        Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.airEmissionsNote}}</div>
      <div class="table-title mt-5">
        7. Provide details of greenhouse gas emissions (Scope 1 and Scope 2 emissions) & its intensity, in the following
        format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th style="width: 40%;">Parameter</th>
            <th style="width: 20%;">Unit</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Total Scope 1 emissions
              (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Total Scope 2 emissions
              (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Total Scope 1 and Scope 2 emission intensity per rupee of turnover
              (Total Scope 1 and Scope 2 GHG emissions / Revenue from operations)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Total Scope 1 and Scope 2 emission intensity per rupee of turnover adjusted for Purchasing Power Parity
              (PPP)
              (Total Scope 1 and Scope 2 GHG emissions / Revenue from operations adjusted for PPP)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Total Scope 1 and Scope 2 emission intensity in terms of physical output</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Total Scope 1 and Scope 2 emission intensity
              (optional) – the relevant metric may be selected by the entity</td>
            <td>{{unit}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-sub-title mt-3">
        Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.externalAgencyEvaluation}}</div>
      <div class="table-title mt-5">
        8. Does the entity have any project related to reducing Green House Gas emission? If Yes, then provide details.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.greenHouseReductionProjects}}</div>
      <div class="table-title mt-5">
        9. Provide details related to waste management by the entity, in the following format:
      </div>

      <table class="table mt-3">
        <thead>
          <tr>
            <th>Parameter</th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" class="text-center text-black fw-semibold">
              Total Waste generated (in metric tonnes)
            </td>
          </tr>

          <tr>
            <td>Plastic waste (A)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>E-waste (B)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Bio-medical waste (C)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Construction and demolition waste (D)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Battery waste (E)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Radioactive waste (F)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Other Hazardous waste. Please specify, if any. (G)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Other Non-hazardous waste generated (H). Please specify, if any. (Break-up by composition i.e. by
              materials relevant to the sector)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total (A+B + C + D + E + F + G + H)h</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Waste intensity per rupee of turnover
              (Total waste generated / Revenue from operations)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Waste intensity per rupee of turnover adjusted for Purchasing Power Parity (PPP)
              (Total waste generated / Revenue from operations adjusted for PPP)</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Waste intensity in terms of physical output</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>Waste intensity
              (optional) – the relevant metric may be selected by the entity</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-center text-black fw-semibold">
              For each category of waste generated, total waste recovered through recycling, re-using or other recovery
              operations (in metric tonnes)
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-start text-black fw-semibold">
              Category of waste
            </td>
          </tr>

          <tr>
            <td>(i) Recycled</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(ii) Re-used</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(iii) Other recovery operations</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total </td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-center text-black fw-semibold">
              For each category of waste generated, total waste disposed by nature of disposal method (in metric tonnes)
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-start text-black fw-semibold">
              Category of waste
            </td>
          </tr>

          <tr>
            <td>(i) Incineration</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(ii) Landfilling</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>(iii) Other disposal operations</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total </td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-sub-title mt-3">
        Note: IIndicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2">
        {{content}}
      </div>
      <div class="table-title mt-5">
        10. Briefly describe the waste management practices adopted in your establishments. Describe the strategy
        adopted by your company to reduce usage of hazardous and toxic chemicals in your products and processes and the
        practices adopted to manage such wastes.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.wasteManagementPractice}}</div>
      <div class="table-title mt-5">
        11. If the entity has operations/offices in/around ecologically sensitive areas (such as national parks,
        wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, coastal regulation zones
        etc.) where environmental approvals/ clearances are required, please specify details in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Location of operations/offices</th>
            <th>Type of operations</th>
            <th>
              Whether the conditions of environmental
              approval / clearance are being complied with?
              (Y/N) If no, the reasons thereof and
              corrective action taken, if any.
            </th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionC.principleSix.operationInSensitiveAreas as |item index| }}
          <td>{{addNumber @index 1}}</td>
          <td>{{item.location}}</td>
          <td>{{item.typeOfOperations}}</td>
          <td>{{item.clearanceComplied}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
      <div class="table-title mt-5">
        12. Details of environmental impact assessments of projects undertaken by the entity based on applicable laws,
        in the current financial year:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th></th>
            <th>Name and brief details of project</th>
            <th class="text-end">EIA Notification No.</th>
            <th class="text-end">Date</th>
            <th>Whether conducted by independent external agency (Yes / No)</th>
            <th>Results communicated in public domain (Yes / No)</th>
            <th>Relevant Web link</th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionC.principleSix.environmentalImpact }}
          <td>{{S. No}}</td>
          <td>{{name}}</td>
          <td class="text-end">{{eiaNo}}</td>
          <td class="text-end">{{date}}</td>
          <td>{{externalAgency}}</td>
          <td>{{publicCommunication}}</td>
          <td>{{webLink}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
      <div class="table-title mt-5">
        13. Is the entity compliant with the applicable environmental law/ regulations/ guidelines in India; such as the
        Water (Prevention and Control of Pollution) Act, Air (Prevention and Control of Pollution) Act, Environment
        protection act and rules thereunder (Y/N). If not, provide details of all such non-compliances, in the following
        format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Specify the law / regulation / guidelines which was not complied with</th>
            <th>Provide details of the non-compliance</th>
            <th>Any fines / penalties / action taken by regulatory agencies such as pollution control boards or by
              courts</th>
            <th>Corrective action taken, if any</th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionC.principleSix.environMentalLawCompliance as |item index| }}
          <td>{{addNumber @index 1}}</td>
          <td>{{item.specifyLaw}}</td>
          <td>{{item.detailsOfNonCompliance}}</td>
          <td>{{item.conditionsComplied}}</td>
          <td>{{item.correctiveActions}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
      <div class="d-flex align-items-center gap-2 mt-3">
        <div class="principle-button-type">
          Principle 6
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>

      <div class="table-title mt-3 mb-0">
        1. Water withdrawal, consumption and discharge in areas of water stress (in kilolitres):
      </div>

      <div class="table-sub-title my-2">
        For each facility / plant located in areas of water stress, provide the following information:
      </div>
      <div class="normal-input fw-semibold">
        i. Name of the area
      </div>
      <div class="gray-border-input">
        {{nameOfArea}}
      </div>
      <div class="gray-border-input fw-semibold">
        ii. Nature of operations
      </div>
      <div class="gray-border-input">
        {{natureOfOperation}}
      </div>

      <div class="table-title mt-5">
        iii. Water withdrawal, consumption and discharge in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Unit</th>
            <th class="text-end">
              <div>{{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>{{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3" class="text-start text-black fw-semibold">Water withdrawal by source (in kilolitres)</td>
          </tr>
          <tr>
            <td>(i) Surface water</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          <tr>

            <td>(ii) Groundwater</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          <tr>
            <td>(iii) Third party water</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          <tr>
            <td>(iv) Seawater/ desalinated water</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          <tr>
            <td>(v) Others</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          <td>Total volume of water withdrawal (in kilolitres) (i + ii + iii + iv + v)</td>
          <td>{{Gj}}</td>
          <td class="text-end">{{currentYear}}</td>
          <td class="text-end">{{previousYear}}</td>
          </tr>
          <td>Total volume of water consumption (in kilolitres)</td>
          <td>{{Gj}}</td>
          <td class="text-end">{{currentYear}}</td>
          <td class="text-end">{{previousYear}}</td>
          </tr>
          <td>Water intensity per rupee of turnover
            (Total water consumption / Revenue from operations)</td>
          <td>{{Gj}}</td>
          <td class="text-end">{{currentYear}}</td>
          <td class="text-end">{{previousYear}}</td>
          </tr>

          <tr>
            <td colspan="3" class="text-start text-black fw-semibold">
              Water discharge by destination and level of treatment (in kilolitres)
            </td>
          </tr>
          <tr>
            <td class="fw-semibold">(i) Surface water</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(ii) To Groundwater</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(iii) To Seawater</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(iv) Sent to third-parties</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">(v) Others</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>No treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td>With treatment – please specify level of treatment</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total water discharged (in kilolitres)</td>
            <td>{{Gj}}</td>
            <td class="text-end">{{returnAlternateValue currentYear "number"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "number"}}</td>
          </tr>



        </tbody>
      </table>
      <div class="table-sub-title mt-3">
        Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.waterDischargeNote}}</div>
      <div class="table-title mt-5">
        2. Please provide details of total Scope 3 emissions & its intensity, in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Unit</th>
            <th class="text-end">
              <div>{{fyear}}</div>
              <div>(Current Financial Year)</div>
            </th>
            <th class="text-end">
              <div>{{previousYear}}</div>
              <div>(Previous Financial Year)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Scope 3 emissions
              (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)</td>
            <td>{{unit}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          <tr>
            <td>Total Scope 3 emissions per rupee of turnover</td>
            <td>{{unit}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
          <tr>
            <td>Total Scope 3 emission intensity
              (optional) – the relevant metric may be selected by the entity</td>
            <td>{{unit}}</td>
            <td class="text-end">{{currentYear}}</td>
            <td class="text-end">{{previousYear}}</td>
          </tr>
        </tbody>
      </table>
      <div class="table-sub-title mt-3">
        Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency?
        (Y/N) If yes, name of the external agency.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.scope3EmissionNote}}</div>
      <div class="table-title mt-5">
        3. With respect to the ecologically sensitive areas reported at Question 11 of Essential Indicators above,
        provide details of significant direct & indirect impact of the entity on biodiversity in such areas along-with
        prevention and remediation activities.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.impactOnBiodiversity}}</div>
      <div class="table-title mt-5">
        4. If the entity has undertaken any specific initiatives or used innovative technology or solutions to improve
        resource efficiency, or reduce impact due to emissions / effluent discharge / waste generated, please provide
        details of the same as well as outcome of such initiatives, as per the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Initiative undertaken</th>
            <th>Details of the initiative (Web-link, if any, may be provided along-with summary)</th>
            <th>Outcome of the initiative</th>
          </tr>
        </thead>
        <tbody>
          {{#each data.SectionC.principleSix.innovativeTechnologyUsed as |item index|}}
          <td>{{addNumber @index 1}}</td>
          <td>{{item.initiativeUndertaken}}</td>
          <td>{{item.initiativeDetails}}</td>
          <td>{{item.intiativeOutcome}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>

      <div class="table-title mt-5 mb-0">
        5. Does the entity have a business continuity and disaster management plan? Give details in 100 words/ web link.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSix.disasterManagementPlan}}</div>
      <div class="table-title mt-5 mb-0">6. Disclose any significant adverse impact to the environment, arising from the
        value chain of the entity. What mitigation or adaptation measures have been taken by the entity in this regard.
      </div>
      <div class="normal-input y-2">{{data.SectionC.principleSix.impactOnEnviromentByValuChain}}</div>
      <div class="table-title mt-5 mb-0">
        7. Percentage of value chain partners (by value of business done with such partners) that were assessed for
        environmental impacts.
      </div>
      <div class="normal-input my-2">
        {{returnAlternateValue data.SectionC.principleSix.percentageOfValuChainPartners "percent"}}
      </div>
    </div>
  </div>

  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="principle-wrapper mt-2">
        <div class="principle-title">
          PRINCIPLE-7:
        </div>
        <div class="principle-description">
          Businesses, when engaging in
          influencing public and regulatory
          policy, should do so in a manner that is
          responsible and transparent
        </div>
      </div>
      <div class="table-sub-title mt-3">Essential Indicators</div>
      <div class="table-title mb-0 mt-4">
        1. a. Number of affiliations with trade and industry chambers/ associations.
      </div>
      <div class="normal-input my-2">{{data.SectionC.principleSeven.affiliatedWithTrade}}</div>
      <div class="table-title mb-0">
        b. List the top 10 trade and industry chambers/ associations (determined based on the total members of such
        body) the entity is a member of/ affiliated to.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr.No.</th>
            <th>
              Name of the trade and industry chambers/ associations
            </th>
            <th>
              Reach of trade and industry chambers/
              associations (State/National)
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>{{item.nameOfTrade}}</td>
            <td>{{item.reachOfTrade}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mb-0">
        2. Provide details of corrective action taken or underway on any issues related to anticompetitive conduct by
        the entity, based on adverse orders from regulatory authorities.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Name of the authority</th>
            <th>
              Brief of the case
            </th>
            <th>
              Corrective action taken
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{nameOfAuthority}}</td>
            <td>{{briefOfCase}}</td>
            <td>{{correctiveActions}}</td>
          </tr>

        </tbody>
      </table>
      <div class="d-flex align-items-center gap-2 mt-3">
        <div class="principle-button-type">
          Principle 1
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>
      <div class="table-title mb-0">
        1. Details of public policy positions advocated by the entity:
      </div>
      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Public policy advocated</th>
            <th>Method resorted for such advocacy</th>
            <th>Whether information available in public domain? (Yes/No)</th>
            <th>Frequency of Review by Board(Annually/ Half yearly/ Quarterly / Others - please specify)</th>
            <th>Web Link, if available</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>{{item.publicPolicyAdvocate}}</td>
            <td>-</td>
            <td>{{item.infoAvailableToPublic}}</td>
            <td>{{item.frequencyOfReview}}</td>
            <td>{{item.webLink}}</td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>

  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="principle-wrapper mt-2">
        <div class="principle-title">
          PRINCIPLE-8:
        </div>
        <div class="principle-description">
          Businesses should promote inclusive
          growth and equitable development
        </div>
      </div>

      <div class="table-sub-title mt-3">Essential Indicators</div>
      <div class="table-title mb-0">
        1. Details of Social Impact Assessments (SIA) of projects undertaken by the entity based on applicable laws, in
        the current financial year.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>
              Name and brief details of project
            </th>
            <th>
              SIA notification no.
            </th>
            <th>
              Date of notification
            </th>
            <th>
              Whether conducted by independent external agency (Yes / No)
            </th>
            <th>
              Results communicated in public domain
            </th>
            <th>
              Relevant Web Link
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{projectName}}</td>
            <td>{{siaNotifNo}}</td>
            <td>{{dateOfNotif}}</td>
            <td>{{conductedByExternalAgency}}</td>
            <td>{{conductedByExternalAgency}}</td>
            <td>{{webLink}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        2. Provide information on project(s) for which ongoing Rehabilitation and Resettlement (R&R) is being undertaken
        by your entity, in the following format:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name of project for which R&R is ongoing</th>
            <th>State</th>
            <th>District</th>
            <th>No. of Project Affected Families (PAFs)</th>
            <th class="text-end">% of PAFs covered by R&R</th>
            <th class="text-end">Amounts paid to PAFs in the FY (In INR)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>{{item.projectName}}</td>
            <td>{{item.state}}</td>
            <td>{{item.district}}</td>
            <td class="text-end">{{returnAlternateValue item.pafAmountPaid "number"}}</td>
            <td class="text-end">{{returnAlternateValue item.pafCovered "percent"}}</td>
            <td class="text-end">{{returnAlternateValue item.pafAmountPaid "number"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        3. Describe the mechanisms to receive and redress grievances of the community.
      </div>
      <div class="normal-input my-3">{{data.SectionC.principleEight.grievanceMechanism}}</div>
      <div class="table-title mt-2">
        4. Percentage of input material (inputs to total inputs by value) sourced from suppliers:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>Current Financial Year</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>Previous Financial Year</div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Directly sourced from MSMEs/ Small producers (%)</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
          </tr>
          <tr>
            <td>Sourced directly from within the district and neighboring districts (%)</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        5. Job creation in smaller towns – Disclose wages paid to persons employed (including employees or workers
        employed on a permanent or non-permanent / on contract basis) in the following locations, as % of total wage
        cost
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th></th>
            <th class="text-end">
              <div>FY {{fyear}}</div>
              <div>Current Financial Year</div>
            </th>
            <th class="text-end">
              <div>FY {{previousYear}}</div>
              <div>Previous Financial Year</div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Rural</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
          </tr>

          <tr>
            <td>Semi-urban</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
          </tr>

          <tr>
            <td>urban</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
          </tr>
          <tr>
            <td>Metropolitan</td>
            <td class="text-end">{{returnAlternateValue currentYear "percent"}}</td>
            <td class="text-end">{{returnAlternateValue previousYear "percent"}}</td>
          </tr>


        </tbody>
      </table>
      <div class="d-flex align-items-center gap-2 mt-3">
        <div class="principle-button-type">
          Principle 8
        </div>
        <div class="table-sub-title">Leadership Indicators</div>
      </div>

      <div class="table-title mb-0">
        1. Provide details of actions taken to mitigate any negative social impacts identified in the Social Impact
        Assessments (Reference: Question 1 of Essential Indicators above):
      </div>
      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Details of negative social impact identified</th>
            <th>Corrective action taken</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{negativeSocialImpact}}</td>
            <td>{{correctiveActions}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        2. Provide the following information on CSR projects undertaken by your entity in designated aspirational
        districts as identified by government bodies:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>State</th>
            <th>Aspirational District</th>
            <th class="text-end">Amount Spent (in ₹)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>{{item.state}}</td>
            <td>{{item.aspDistrict}}</td>
            <td class="text-end">{{item.amountSpent}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        3. a. Do you have a preferential procurement policy where you give preference to purchase from suppliers
        comprising marginalized /vulnerable groups? (Yes/No)
      </div>
      <div class="normal-input my-3">{{data.SectionC.principleEight.preferentialProcurementPolicy}}</div>
      <div class="table-title mt-2">
        3. b. From which marginalized vulnerable groups do you procure?
      </div>
      <div class="normal-input my-3">{{data.SectionC.principleEight.procurementGroup}}</div>
      <div class="table-title mt-2">
        3. c. What percentage of total procurement (by value) does it constitute?
      </div>
      <div class="normal-input my-3">
        {{returnAlternateValue data.SectionC.principleEight.percentOfTotalProcurement "percent"}}
      </div>
      <div class="table-title mt-5">
        4. Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity
        (in the current financial year), based on traditional knowledge:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>

            <th>Intellectual Property based on traditional knowledge</th>
            <th>Owned/ Acquired (Yes/No)</th>
            <th>Benefit shared (Yes / No)</th>
            <th>Basis of calculating benefit share</th>
          </tr>
        </thead>
        <tbody>

          <tr>

            <td>{{item.intellectualProperty}}</td>
            <td>{{item.owned}}</td>
            <td>{{item.benifitsShared}}</td>
            <td>{{item.basisOfCalculation}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        5. Details of corrective actions taken or underway, based on any adverse order in intellectual property related
        disputes wherein usage of traditional knowledge is involved.
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Name of authority</th>
            <th>Brief of the Case</th>
            <th>Corrective action taken</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{authorityName}}</td>
            <td>{{briefOfCase}}</td>
            <td>{{correctiveActions}}</td>
          </tr>

        </tbody>
      </table>
      <div class="table-title mt-5">
        6. Details of beneficiaries of CSR Projects:
      </div>

      <table class="table-sectionC mt-3">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>CSR Project</th>
            <th class="text-end">No. of persons benefitted from CSR projects (in Million)</th>
            <th class="text-end">% of beneficiaries from vulnerable and marginalized groups</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>Education</td>
            <td class="text-end">{{returnAlternateValue item.peopleBenifited "number"}}</td>
            <td class="text-end">{{returnAlternateValue item.vulnerablePeople "percent"}}</td>
          </tr>
          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>Community Healthcare</td>
            <td class="text-end">{{returnAlternateValue item.peopleBenifited "number"}}</td>
            <td class="text-end">{{returnAlternateValue item.vulnerablePeople "percent"}}</td>
          </tr>
          <tr>
            <td>{{addNumber @index 1}}.</td>
            <td>Community Infrastructure Development</td>
            <td class="text-end">{{returnAlternateValue item.peopleBenifited "number"}}</td>
            <td class="text-end">{{returnAlternateValue item.vulnerablePeople "percent"}}</td>
          </tr>
          <tr>
            <td></td>
            <td class="fw-semibold ">Total</td>
            <td class="text-end">{{returnAlternateValue item.peopleBenifited "number"}}</td>
            <td class="text-end">{{returnAlternateValue item.vulnerablePeople "percent"}}</td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>

  <div class="page">
    <div class="content-wrapper general-disclosure-page">
      <div class="mt-3 d-flex align-items-baseline mb-3">
        <div class="d-flex align-items-center gap-2">
          <img src="" alt="logo" width="56">
          <div class="page-header">Business Responsibility & Sustainability Report</div>
        </div>
        <div class="dotted-line"></div>
        <div class="page-number pt-2">{{BRSR 2024-25}}</div>
      </div>
      <div class="principle-wrapper mt-2"></div>
      <div class="principle-title">
        PRINCIPLE-9:
      </div>
      <div class="principle-description">
        Businesses should engage with and provide value to their consumers in a responsible manner
      </div>
    </div>

    <div class="table-sub-title mt-3">Essential Indicators</div>

    <div class="table-title mt-3">
      1. Describe the mechanisms in place to receive and respond to consumer complaints and feedback.
    </div>
    <div class="normal-input my-3">{{data.SectionC.principleNine.consumerComplaintResponseMechanism}}
    </div>
    <div class="table-title mt-5 mb-0 ">
      2. Turnover of products and/ services as a percentage of turnover from all products/service that carry information
      about:
    </div>

    <table class="table-sectionC mt-3">
      <thead>
        <tr>
          <th></th>
          <th>As a percentage to total turnover</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>Environmental and social parameters relevant to the product</td>
          <td>{{returnAlternateValue percentOfTotal "percent"}}</td>
        </tr>
        <tr>
          <td>Safe and responsible usage</td>
          <td>{{returnAlternateValue percentOfTotal "percent"}}</td>
        </tr>
        <tr>
          <td>Recycling and/or safe disposal</td>
          <td>{{returnAlternateValue percentOfTotal "percent"}}</td>
        </tr>


      </tbody>
    </table>

    <div class="table-title mt-5">
      3. Number of consumer complaints in respect of the following:
    </div>

    <table class="table-sectionC mt-3">
      <thead>
        <tr>
          <th rowspan="2"></th>
          <th colspan="3" class="text-center">FY {{fyear}} (Current Financial Year)</th>
          <th colspan="3" class="text-center">FY {{previousYear}} (previousYear Financial Year)</th>
        </tr>
        <tr>
          <th class="text-end rowspan-border">Received during the year</th>
          <th class="text-end">Pending resolution at the end of year</th>
          <th>Remarks</th>
          <th class="text-end">Received during the year</th>
          <th class="text-end">Pending resolution at the end of year</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>Data privacy</td>
          <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
          <td>{{currentYear.remarks}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
          <td>{{previousYear.remarks}}</td>
        </tr>
        <tr>
          <td>Advertising</td>
          <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
          <td>{{currentYear.remarks}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
          <td>{{previousYear.remarks}}</td>
        </tr>
        <tr>
          <td>Cyber-security</td>
          <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
          <td>{{currentYear.remarks}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
          <td>{{previousYear.remarks}}</td>
        </tr>
        <tr>
          <td>Delivery of essential services</td>
          <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
          <td>{{currentYear.remarks}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
          <td>{{previousYear.remarks}}</td>
        </tr>
        <tr>
          <td>Restrictive Trade Practices</td>
          <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
          <td>{{currentYear.remarks}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
          <td>{{previousYear.remarks}}</td>
        </tr>
        <tr>
          <td>Unfair Trade Practices</td>
          <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
          <td>{{currentYear.remarks}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
          <td>{{previousYear.remarks}}</td>
        </tr>
        <tr>
          <td>Other</td>
          <td class="text-end">{{returnAlternateValue currentYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue currentYear.pendingResolution "number"}}</td>
          <td>{{currentYear.remarks}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.total "number"}}</td>
          <td class="text-end">{{returnAlternateValue previousYear.pendingResolution "number"}}</td>
          <td>{{previousYear.remarks}}</td>
        </tr>

      </tbody>
    </table>
    <div class="table-title mt-5">
      4. Details of instances of product recalls on account of safety issues:
    </div>

    <table class="table-sectionC mt-3">
      <thead>
        <tr>
          <th></th>
          <th class="text-end">Number</th>
          <th>Reason for recall</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>Voluntary recalls</td>
          <td class="text-end">{{returnAlternateValue number "percent"}}</td>
          <td>{{reason}}</td>
        </tr>
        <tr>
          <td>Forced recalls</td>
          <td class="text-end">{{returnAlternateValue number "percent"}}</td>
          <td>{{reason}}</td>
        </tr>

      </tbody>
    </table>

    <div class="table-title mt-5">
      5. Does the entity have a framework/ policy on cyber security and risks related to data privacy? (Yes/No) If
      available, provide a web-link of the policy.
    </div>
    <div class="normal-input my-3">{{data.SectionC.principleNine.policyOnCyberSecurity}}</div>
    <div class="table-title mt-5">
      6. Provide details of any corrective actions taken or underway on issues relating to advertising, and delivery of
      essential services; cyber security and data privacy of customers; re-occurrence of instances of product recalls;
      penalty/ action taken by regulatory authorities on safety of products/ services.
    </div>
    <div class="normal-input my-3">{{data.SectionC.principleNine.correctiveActionAdvertising}}</div>
    <div class="table-title mt-5">
      7. Provide the following information relating to data breaches:
    </div>

    <table class="table-sectionC mt-3">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>

        </tr>
      </thead>
      <tbody>

        <tr class="normal-input">
          <td>a.</td>
          <td> Number of instances of data breaches</td>
          <td>{{returnAlternateValue data.SectionC.principleNine.dataBreachInstances "number"}}</td>
        </tr>
        <tr>
          <td>b.</td>
          <td>Percentage of data breaches involving personally identifiable information of customers</td>
          <td> {{returnAlternateValue data.SectionC.principleNine.personalInformationDataBreach "percent"}}</td>
        </tr>
        <tr>
          <td>c.</td>
          <td>Impact, if any, of the data breaches</td>
          <td> {{returnAlternateValue data.SectionC.principleNine.personalInformationDataBreach "percent"}}</td>
        </tr>
      </tbody>
    </table>


    <div class="d-flex align-items-center gap-2 mt-3">
      <div class="principle-button-type">
        Principle 1
      </div>
      <div class="table-sub-title">Leadership Indicators</div>
    </div>
    <div class="table-title mt-3">
      1. Channels / platforms where information on products and services of the entity can be accessed (provide web
      link, if available).
    </div>

    <table class="table-sectionC mt-3">
      <thead>
        <tr>
          <th>Products/Services</th>
          <th>Channels/ Platforms/ Web link</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td> Petchem products</td>
          <td>{{data.SectionC.principleNine.productAccessPlatforms}}</td>
        </tr>
      </tbody>
    </table>


    <div class="table-title mt-5">
      2. Steps taken to inform and educate consumers about safe and responsible usage of products and/or services.
    </div>
    <div class="normal-input my-3">{{data.SectionC.principleNine.safeUsageInformationProvided}}</div>
    <div class="table-title mt-5">
      3. Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential services.
    </div>
    <div class="normal-input my-3">{{data.SectionC.principleNine.mechanismToInformConsumerOfRisk}}
    </div>
    <div class="table-title mt-5">
      4. Does the entity display product information on the product over and above what is mandated as per local laws?
      (Yes/No/Not Applicable) If yes, provide details in brief. Did your entity carry out any survey with regard to
      consumer satisfaction relating to the major products / services of the entity, significant locations of operation
      of the entity or the entity as a whole? (Yes/No)
    </div>
    <div class="normal-input my-3">{{data.SectionC.principleNine.entityDisplaysProductInfo}}
    </div>
  </div>
  </div>
</body>

</html>`;
