export default function findChangedFields(prevState, currentState) {
  const changedFields = {};

  Object.keys(currentState).forEach((key) => {
    if (prevState[key] !== currentState[key]) {
      changedFields[key] = {
        prevValue: prevState[key],
        currentValue: currentState[key]
      };
    }
  });

  return changedFields;
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeOnlyFacilityIdObjects = (arr) => {
  return arr.filter((obj) => !(Object.keys(obj).length === 1 && obj.hasOwnProperty("facilityId")));
};

export function isNestedObjectEmpty(obj) {
  if (typeof obj !== "object" || obj === null) return false;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "object" && !isNestedObjectEmpty(value)) {
        return false;
      }
      if (value !== null && value !== undefined && value !== "") {
        return false;
      }
    }
  }
  return true;
}
